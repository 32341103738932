!function (e, n) {
  "object" == typeof exports && "object" == typeof module ? module.exports = n() : "function" == typeof define && define.amd ? define("microsoftTeams", [], n) : "object" == typeof exports ? exports.microsoftTeams = n() : e.microsoftTeams = n();
}("undefined" != typeof self ? self : this, () => (() => {
  var e = {
      302: (e, n, t) => {
        n.formatArgs = function (n) {
          if (n[0] = (this.useColors ? "%c" : "") + this.namespace + (this.useColors ? " %c" : " ") + n[0] + (this.useColors ? "%c " : " ") + "+" + e.exports.humanize(this.diff), !this.useColors) return;
          const t = "color: " + this.color;
          n.splice(1, 0, t, "color: inherit");
          let o = 0,
            i = 0;
          n[0].replace(/%[a-zA-Z%]/g, e => {
            "%%" !== e && (o++, "%c" === e && (i = o));
          }), n.splice(i, 0, t);
        }, n.save = function (e) {
          try {
            e ? n.storage.setItem("debug", e) : n.storage.removeItem("debug");
          } catch (e) {}
        }, n.load = function () {
          let e;
          try {
            e = n.storage.getItem("debug");
          } catch (e) {}
          !e && "undefined" != typeof process && "env" in process && (e = process.env.DEBUG);
          return e;
        }, n.useColors = function () {
          if ("undefined" != typeof window && window.process && ("renderer" === window.process.type || window.process.__nwjs)) return !0;
          if ("undefined" != typeof navigator && navigator.userAgent && navigator.userAgent.toLowerCase().match(/(edge|trident)\/(\d+)/)) return !1;
          return "undefined" != typeof document && document.documentElement && document.documentElement.style && document.documentElement.style.WebkitAppearance || "undefined" != typeof window && window.console && (window.console.firebug || window.console.exception && window.console.table) || "undefined" != typeof navigator && navigator.userAgent && navigator.userAgent.toLowerCase().match(/firefox\/(\d+)/) && parseInt(RegExp.$1, 10) >= 31 || "undefined" != typeof navigator && navigator.userAgent && navigator.userAgent.toLowerCase().match(/applewebkit\/(\d+)/);
        }, n.storage = function () {
          try {
            return localStorage;
          } catch (e) {}
        }(), n.destroy = (() => {
          let e = !1;
          return () => {
            e || (e = !0, console.warn("Instance method `debug.destroy()` is deprecated and no longer does anything. It will be removed in the next major version of `debug`."));
          };
        })(), n.colors = ["#0000CC", "#0000FF", "#0033CC", "#0033FF", "#0066CC", "#0066FF", "#0099CC", "#0099FF", "#00CC00", "#00CC33", "#00CC66", "#00CC99", "#00CCCC", "#00CCFF", "#3300CC", "#3300FF", "#3333CC", "#3333FF", "#3366CC", "#3366FF", "#3399CC", "#3399FF", "#33CC00", "#33CC33", "#33CC66", "#33CC99", "#33CCCC", "#33CCFF", "#6600CC", "#6600FF", "#6633CC", "#6633FF", "#66CC00", "#66CC33", "#9900CC", "#9900FF", "#9933CC", "#9933FF", "#99CC00", "#99CC33", "#CC0000", "#CC0033", "#CC0066", "#CC0099", "#CC00CC", "#CC00FF", "#CC3300", "#CC3333", "#CC3366", "#CC3399", "#CC33CC", "#CC33FF", "#CC6600", "#CC6633", "#CC9900", "#CC9933", "#CCCC00", "#CCCC33", "#FF0000", "#FF0033", "#FF0066", "#FF0099", "#FF00CC", "#FF00FF", "#FF3300", "#FF3333", "#FF3366", "#FF3399", "#FF33CC", "#FF33FF", "#FF6600", "#FF6633", "#FF9900", "#FF9933", "#FFCC00", "#FFCC33"], n.log = console.debug || console.log || (() => {}), e.exports = t(65)(n);
        const {
          formatters: o
        } = e.exports;
        o.j = function (e) {
          try {
            return JSON.stringify(e);
          } catch (e) {
            return "[UnexpectedJSONParseError]: " + e.message;
          }
        };
      },
      65: (e, n, t) => {
        e.exports = function (e) {
          function n(e) {
            let t,
              i,
              r,
              a = null;
            function s(...e) {
              if (!s.enabled) return;
              const o = s,
                i = Number(new Date()),
                r = i - (t || i);
              o.diff = r, o.prev = t, o.curr = i, t = i, e[0] = n.coerce(e[0]), "string" != typeof e[0] && e.unshift("%O");
              let a = 0;
              e[0] = e[0].replace(/%([a-zA-Z%])/g, (t, i) => {
                if ("%%" === t) return "%";
                a++;
                const r = n.formatters[i];
                if ("function" == typeof r) {
                  const n = e[a];
                  t = r.call(o, n), e.splice(a, 1), a--;
                }
                return t;
              }), n.formatArgs.call(o, e);
              (o.log || n.log).apply(o, e);
            }
            return s.namespace = e, s.useColors = n.useColors(), s.color = n.selectColor(e), s.extend = o, s.destroy = n.destroy, Object.defineProperty(s, "enabled", {
              enumerable: !0,
              configurable: !1,
              get: () => null !== a ? a : (i !== n.namespaces && (i = n.namespaces, r = n.enabled(e)), r),
              set: e => {
                a = e;
              }
            }), "function" == typeof n.init && n.init(s), s;
          }
          function o(e, t) {
            const o = n(this.namespace + (void 0 === t ? ":" : t) + e);
            return o.log = this.log, o;
          }
          function i(e) {
            return e.toString().substring(2, e.toString().length - 2).replace(/\.\*\?$/, "*");
          }
          return n.debug = n, n.default = n, n.coerce = function (e) {
            if (e instanceof Error) return e.stack || e.message;
            return e;
          }, n.disable = function () {
            const e = [...n.names.map(i), ...n.skips.map(i).map(e => "-" + e)].join(",");
            return n.enable(""), e;
          }, n.enable = function (e) {
            let t;
            n.save(e), n.namespaces = e, n.names = [], n.skips = [];
            const o = ("string" == typeof e ? e : "").split(/[\s,]+/),
              i = o.length;
            for (t = 0; t < i; t++) o[t] && ("-" === (e = o[t].replace(/\*/g, ".*?"))[0] ? n.skips.push(new RegExp("^" + e.slice(1) + "$")) : n.names.push(new RegExp("^" + e + "$")));
          }, n.enabled = function (e) {
            if ("*" === e[e.length - 1]) return !0;
            let t, o;
            for (t = 0, o = n.skips.length; t < o; t++) if (n.skips[t].test(e)) return !1;
            for (t = 0, o = n.names.length; t < o; t++) if (n.names[t].test(e)) return !0;
            return !1;
          }, n.humanize = t(247), n.destroy = function () {
            console.warn("Instance method `debug.destroy()` is deprecated and no longer does anything. It will be removed in the next major version of `debug`.");
          }, Object.keys(e).forEach(t => {
            n[t] = e[t];
          }), n.names = [], n.skips = [], n.formatters = {}, n.selectColor = function (e) {
            let t = 0;
            for (let n = 0; n < e.length; n++) t = (t << 5) - t + e.charCodeAt(n), t |= 0;
            return n.colors[Math.abs(t) % n.colors.length];
          }, n.enable(n.load()), n;
        };
      },
      247: e => {
        var n = 1e3,
          t = 60 * n,
          o = 60 * t,
          i = 24 * o,
          r = 7 * i,
          a = 365.25 * i;
        function s(e) {
          if (!((e = String(e)).length > 100)) {
            var s = /^(-?(?:\d+)?\.?\d+) *(milliseconds?|msecs?|ms|seconds?|secs?|s|minutes?|mins?|m|hours?|hrs?|h|days?|d|weeks?|w|years?|yrs?|y)?$/i.exec(e);
            if (s) {
              var l = parseFloat(s[1]);
              switch ((s[2] || "ms").toLowerCase()) {
                case "years":
                case "year":
                case "yrs":
                case "yr":
                case "y":
                  return l * a;
                case "weeks":
                case "week":
                case "w":
                  return l * r;
                case "days":
                case "day":
                case "d":
                  return l * i;
                case "hours":
                case "hour":
                case "hrs":
                case "hr":
                case "h":
                  return l * o;
                case "minutes":
                case "minute":
                case "mins":
                case "min":
                case "m":
                  return l * t;
                case "seconds":
                case "second":
                case "secs":
                case "sec":
                case "s":
                  return l * n;
                case "milliseconds":
                case "millisecond":
                case "msecs":
                case "msec":
                case "ms":
                  return l;
                default:
                  return;
              }
            }
          }
        }
        function l(e) {
          var r = Math.abs(e);
          return r >= i ? Math.round(e / i) + "d" : r >= o ? Math.round(e / o) + "h" : r >= t ? Math.round(e / t) + "m" : r >= n ? Math.round(e / n) + "s" : e + "ms";
        }
        function c(e) {
          var r = Math.abs(e);
          return r >= i ? u(e, r, i, "day") : r >= o ? u(e, r, o, "hour") : r >= t ? u(e, r, t, "minute") : r >= n ? u(e, r, n, "second") : e + " ms";
        }
        function u(e, n, t, o) {
          var i = n >= 1.5 * t;
          return Math.round(e / t) + " " + o + (i ? "s" : "");
        }
        e.exports = function (e, n) {
          n = n || {};
          var t = typeof e;
          if ("string" === t && e.length > 0) return s(e);
          if ("number" === t && isFinite(e)) return n.long ? c(e) : l(e);
          throw new Error("val is not a non-empty string or a valid number. val=" + JSON.stringify(e));
        };
      }
    },
    n = {};
  function t(o) {
    var i = n[o];
    if (void 0 !== i) return i.exports;
    var r = n[o] = {
      exports: {}
    };
    return e[o](r, r.exports, t), r.exports;
  }
  (() => {
    t.d = (e, n) => {
      for (var o in n) t.o(n, o) && !t.o(e, o) && Object.defineProperty(e, o, {
        enumerable: !0,
        get: n[o]
      });
    };
  })(), (() => {
    t.o = (e, n) => Object.prototype.hasOwnProperty.call(e, n);
  })(), (() => {
    t.r = e => {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(e, "__esModule", {
        value: !0
      });
    };
  })();
  var o = {};
  return (() => {
    "use strict";

    t.r(o), t.d(o, {
      ActionObjectType: () => R,
      ChannelType: () => x,
      ChildAppWindow: () => Pt,
      ContainerState: () => bo,
      DialogDimension: () => W,
      ErrorCode: () => M,
      FileOpenPreference: () => A,
      FrameContexts: () => U,
      HostClientType: () => N,
      HostName: () => L,
      LiveShareHost: () => Oo,
      NotificationTypes: () => In,
      ParentAppWindow: () => Ft,
      SecondaryM365ContentIdName: () => O,
      TaskModuleDimension: () => _,
      TeamType: () => H,
      UserMeetingRole: () => So,
      UserSettingTypes: () => Pn,
      UserTeamRole: () => V,
      ViewerActionTypes: () => En,
      app: () => be,
      appEntity: () => ko,
      appInitialization: () => jt,
      appInstallDialog: () => Bn,
      authentication: () => Ce,
      barCode: () => lt,
      calendar: () => Rt,
      call: () => zt,
      chat: () => ct,
      conversations: () => Fn,
      dialog: () => ve,
      enablePrintCapability: () => Qt,
      executeDeepLink: () => po,
      files: () => Io,
      geoLocation: () => ut,
      getAdaptiveCardSchemaVersion: () => It,
      getContext: () => $t,
      getMruTabInstances: () => uo,
      getTabInstances: () => co,
      initialize: () => Jt,
      initializeWithFrameContext: () => mo,
      location: () => Et,
      logs: () => Tn,
      mail: () => Ot,
      media: () => st,
      meeting: () => kt,
      meetingRoom: () => Eo,
      menus: () => ye,
      monetization: () => At,
      navigateBack: () => vo,
      navigateCrossDomain: () => Co,
      navigateToTab: () => wo,
      notifications: () => Po,
      openFilePreview: () => _n,
      pages: () => Te,
      people: () => Mt,
      print: () => Xt,
      profile: () => Dt,
      registerAppButtonClickHandler: () => no,
      registerAppButtonHoverEnterHandler: () => to,
      registerAppButtonHoverLeaveHandler: () => oo,
      registerBackButtonHandler: () => io,
      registerBeforeUnloadHandler: () => ao,
      registerChangeSettingsHandler: () => lo,
      registerCustomHandler: () => Wn,
      registerFocusEnterHandler: () => so,
      registerFullScreenHandler: () => eo,
      registerOnLoadHandler: () => ro,
      registerOnThemeChangeHandler: () => Yt,
      registerUserSettingsChangeHandler: () => xn,
      remoteCamera: () => Fo,
      returnFocus: () => ho,
      search: () => Wt,
      sendCustomEvent: () => Vn,
      sendCustomMessage: () => Hn,
      setFrameContext: () => go,
      settings: () => Gt,
      shareDeepLink: () => fo,
      sharing: () => xt,
      stageView: () => _t,
      tasks: () => yo,
      teams: () => Ao,
      teamsCore: () => Se,
      uploadCustomApp: () => Un,
      version: () => Ie,
      video: () => Vt,
      videoEx: () => Ro,
      webStorage: () => Bt
    });
    var e = "2.0.1",
      n = "2.0.2",
      i = "2.0.3",
      r = "2.0.4",
      a = "2.0.1",
      s = "1.9.0",
      l = "2.0.0",
      c = "1.7.0",
      u = "1.8.0",
      d = "1.9.0",
      f = ["teams.microsoft.com", "teams.microsoft.us", "gov.teams.microsoft.us", "dod.teams.microsoft.us", "int.teams.microsoft.com", "teams.live.com", "devspaces.skype.com", "ssauth.skype.com", "local.teams.live.com", "local.teams.live.com:8080", "local.teams.office.com", "local.teams.office.com:8080", "outlook.office.com", "outlook-sdf.office.com", "outlook.office365.com", "outlook-sdf.office365.com", "outlook.live.com", "outlook-sdf.live.com", "*.teams.microsoft.com", "*.www.office.com", "www.office.com", "word.office.com", "excel.office.com", "powerpoint.office.com", "www.officeppe.com", "*.www.microsoft365.com", "www.microsoft365.com"],
      p = /^https:\/\//,
      g = "https",
      m = "teams.microsoft.com",
      h = "The library has not yet been initialized",
      w = "The runtime has not yet been initialized",
      C = "The runtime version is not supported",
      v = function () {
        function e() {}
        return e.initializeCalled = !1, e.initializeCompleted = !1, e.additionalValidOrigins = [], e.isFramelessWindow = !1, e.printCapabilityEnabled = !1, e;
      }(),
      y = (0, t(302).debug)("teamsJs");
    function S(e) {
      return y.extend(e);
    }
    const b = {
      randomUUID: "undefined" != typeof crypto && crypto.randomUUID && crypto.randomUUID.bind(crypto)
    };
    let T;
    const I = new Uint8Array(16);
    function E() {
      if (!T && (T = "undefined" != typeof crypto && crypto.getRandomValues && crypto.getRandomValues.bind(crypto), !T)) throw new Error("crypto.getRandomValues() not supported. See https://github.com/uuidjs/uuid#getrandomvalues-not-supported");
      return T(I);
    }
    const P = [];
    for (let e = 0; e < 256; ++e) P.push((e + 256).toString(16).slice(1));
    function F(e, n = 0) {
      return (P[e[n + 0]] + P[e[n + 1]] + P[e[n + 2]] + P[e[n + 3]] + "-" + P[e[n + 4]] + P[e[n + 5]] + "-" + P[e[n + 6]] + P[e[n + 7]] + "-" + P[e[n + 8]] + P[e[n + 9]] + "-" + P[e[n + 10]] + P[e[n + 11]] + P[e[n + 12]] + P[e[n + 13]] + P[e[n + 14]] + P[e[n + 15]]).toLowerCase();
    }
    const k = function (e, n, t) {
      if (b.randomUUID && !n && !e) return b.randomUUID();
      const o = (e = e || {}).random || (e.rng || E)();
      if (o[6] = 15 & o[6] | 64, o[8] = 63 & o[8] | 128, n) {
        t = t || 0;
        for (let e = 0; e < 16; ++e) n[t + e] = o[e];
        return n;
      }
      return F(o);
    };
    var A, R, O, M, D, N, L, U, H, V, W;
    !function (e) {
      e.Inline = "inline", e.Desktop = "desktop", e.Web = "web";
    }(A || (A = {})), function (e) {
      e.M365Content = "m365content";
    }(R || (R = {})), function (e) {
      e.DriveId = "driveId", e.GroupId = "groupId", e.SiteId = "siteId", e.UserId = "userId";
    }(O || (O = {})), function (e) {
      e[e.NOT_SUPPORTED_ON_PLATFORM = 100] = "NOT_SUPPORTED_ON_PLATFORM", e[e.INTERNAL_ERROR = 500] = "INTERNAL_ERROR", e[e.NOT_SUPPORTED_IN_CURRENT_CONTEXT = 501] = "NOT_SUPPORTED_IN_CURRENT_CONTEXT", e[e.PERMISSION_DENIED = 1e3] = "PERMISSION_DENIED", e[e.NETWORK_ERROR = 2e3] = "NETWORK_ERROR", e[e.NO_HW_SUPPORT = 3e3] = "NO_HW_SUPPORT", e[e.INVALID_ARGUMENTS = 4e3] = "INVALID_ARGUMENTS", e[e.UNAUTHORIZED_USER_OPERATION = 5e3] = "UNAUTHORIZED_USER_OPERATION", e[e.INSUFFICIENT_RESOURCES = 6e3] = "INSUFFICIENT_RESOURCES", e[e.THROTTLE = 7e3] = "THROTTLE", e[e.USER_ABORT = 8e3] = "USER_ABORT", e[e.OPERATION_TIMED_OUT = 8001] = "OPERATION_TIMED_OUT", e[e.OLD_PLATFORM = 9e3] = "OLD_PLATFORM", e[e.FILE_NOT_FOUND = 404] = "FILE_NOT_FOUND", e[e.SIZE_EXCEEDED = 1e4] = "SIZE_EXCEEDED";
    }(M || (M = {})), function (e) {
      e.GeoLocation = "geolocation", e.Media = "media";
    }(D || (D = {})), function (e) {
      e.desktop = "desktop", e.web = "web", e.android = "android", e.ios = "ios", e.ipados = "ipados", e.rigel = "rigel", e.surfaceHub = "surfaceHub", e.teamsRoomsWindows = "teamsRoomsWindows", e.teamsRoomsAndroid = "teamsRoomsAndroid", e.teamsPhones = "teamsPhones", e.teamsDisplays = "teamsDisplays";
    }(N || (N = {})), function (e) {
      e.office = "Office", e.outlook = "Outlook", e.outlookWin32 = "OutlookWin32", e.orange = "Orange", e.teams = "Teams", e.teamsModern = "TeamsModern";
    }(L || (L = {})), function (e) {
      e.settings = "settings", e.content = "content", e.authentication = "authentication", e.remove = "remove", e.task = "task", e.sidePanel = "sidePanel", e.stage = "stage", e.meetingStage = "meetingStage";
    }(U || (U = {})), function (e) {
      e[e.Standard = 0] = "Standard", e[e.Edu = 1] = "Edu", e[e.Class = 2] = "Class", e[e.Plc = 3] = "Plc", e[e.Staff = 4] = "Staff";
    }(H || (H = {})), function (e) {
      e[e.Admin = 0] = "Admin", e[e.User = 1] = "User", e[e.Guest = 2] = "Guest";
    }(V || (V = {})), function (e) {
      e.Large = "large", e.Medium = "medium", e.Small = "small";
    }(W || (W = {}));
    var x,
      _ = W;
    !function (e) {
      e.Regular = "Regular", e.Private = "Private", e.Shared = "Shared";
    }(x || (x = {}));
    var B = {
        errorCode: M.NOT_SUPPORTED_ON_PLATFORM
      },
      z = {
        majorVersion: 1,
        minorVersion: 5
      };
    function j(e, n) {
      if ("*." === e.substring(0, 2)) {
        var t = e.substring(1);
        if (n.length > t.length && n.split(".").length === t.split(".").length && n.substring(n.length - t.length) === t) return !0;
      } else if (e === n) return !0;
      return !1;
    }
    function G(e) {
      if ("https:" !== e.protocol) return !1;
      var n = e.host;
      if (f.some(function (e) {
        return j(e, n);
      })) return !0;
      for (var t = 0, o = v.additionalValidOrigins; t < o.length; t++) {
        var i = o[t];
        if (j("https://" === i.substring(0, 8) ? i.substring(8) : i, n)) return !0;
      }
      return !1;
    }
    function q(e) {
      return function (n, t) {
        if (!n) throw new Error(e || t);
      };
    }
    function K(e, n) {
      if ("string" != typeof e || "string" != typeof n) return NaN;
      var t = e.split("."),
        o = n.split(".");
      function i(e) {
        return /^\d+$/.test(e);
      }
      if (!t.every(i) || !o.every(i)) return NaN;
      for (; t.length < o.length;) t.push("0");
      for (; o.length < t.length;) o.push("0");
      for (var r = 0; r < t.length; ++r) if (Number(t[r]) != Number(o[r])) return Number(t[r]) > Number(o[r]) ? 1 : -1;
      return 0;
    }
    function Z() {
      return k();
    }
    function J(e) {
      return Object.keys(e).forEach(function (n) {
        "object" == typeof e[n] && J(e[n]);
      }), Object.freeze(e);
    }
    function Q(e, n) {
      for (var t = [], o = 2; o < arguments.length; o++) t[o - 2] = arguments[o];
      var i = e.apply(void 0, t);
      return i.then(function (e) {
        n && n(void 0, e);
      }).catch(function (e) {
        n && n(e);
      }), i;
    }
    function X(e, n) {
      for (var t = [], o = 2; o < arguments.length; o++) t[o - 2] = arguments[o];
      var i = e.apply(void 0, t);
      return i.then(function () {
        n && n(null);
      }).catch(function (e) {
        n && n(e);
      }), i;
    }
    function $(e, n) {
      for (var t = [], o = 2; o < arguments.length; o++) t[o - 2] = arguments[o];
      var i = e.apply(void 0, t);
      return i.then(function (e) {
        n && n(null, e);
      }).catch(function (e) {
        n && n(e, null);
      }), i;
    }
    function Y(e, n, t) {
      return new Promise(function (o, i) {
        var r = setTimeout(i, n, t);
        e().then(function (e) {
          clearTimeout(r), o(e);
        }).catch(function (e) {
          clearTimeout(r), i(e);
        });
      });
    }
    function ee(e) {
      var n = new URL("https://teams.microsoft.com/l/entity/" + encodeURIComponent(e.appId) + "/" + encodeURIComponent(e.pageId));
      return e.webUrl && n.searchParams.append("webUrl", e.webUrl), (e.channelId || e.subPageId) && n.searchParams.append("context", JSON.stringify({
        channelId: e.channelId,
        subEntityId: e.subPageId
      })), n.toString();
    }
    function ne(e) {
      return !(K("".concat(e.majorVersion, ".").concat(e.minorVersion), "".concat(z.majorVersion, ".").concat(z.minorVersion)) >= 0);
    }
    var te = function () {
        return te = Object.assign || function (e) {
          for (var n, t = 1, o = arguments.length; t < o; t++) for (var i in n = arguments[t]) Object.prototype.hasOwnProperty.call(n, i) && (e[i] = n[i]);
          return e;
        }, te.apply(this, arguments);
      },
      oe = S("runtime"),
      ie = 2;
    function re(e) {
      return e.apiVersion === ie;
    }
    var ae = {
      apiVersion: -1,
      supports: {}
    };
    function se(e) {
      if (re(e)) return !0;
      throw -1 === e.apiVersion ? new Error(w) : new Error(C);
    }
    var le = ae,
      ce = {
        apiVersion: 2,
        hostVersionsInfo: void 0,
        isLegacyTeams: !0,
        supports: {
          appInstallDialog: {},
          appEntity: {},
          call: {},
          chat: {},
          conversations: {},
          dialog: {
            url: {
              bot: {}
            },
            update: {}
          },
          logs: {},
          meetingRoom: {},
          menus: {},
          monetization: {},
          notifications: {},
          pages: {
            appButton: {},
            tabs: {},
            config: {},
            backStack: {},
            fullTrust: {}
          },
          remoteCamera: {},
          stageView: {},
          teams: {
            fullTrust: {}
          },
          teamsCore: {},
          video: {
            sharedFrame: {}
          }
        }
      },
      ue = [N.desktop, N.web, N.android, N.ios, N.rigel, N.surfaceHub, N.teamsRoomsWindows, N.teamsRoomsAndroid, N.teamsPhones, N.teamsDisplays];
    function de(e) {
      var n = e;
      if (n.apiVersion < ie && fe.forEach(function (e) {
        n.apiVersion === e.versionToUpgradeFrom && (n = e.upgradeToNextVersion(n));
      }), re(n)) return n;
      throw new Error("Received a runtime that could not be upgraded to the latest version");
    }
    var fe = [{
        versionToUpgradeFrom: 1,
        upgradeToNextVersion: function (e) {
          var n;
          return {
            apiVersion: 2,
            hostVersionsInfo: void 0,
            isLegacyTeams: e.isLegacyTeams,
            supports: te(te({}, e.supports), {
              dialog: e.supports.dialog ? {
                card: void 0,
                url: e.supports.dialog,
                update: null === (n = e.supports.dialog) || void 0 === n ? void 0 : n.update
              } : void 0
            })
          };
        }
      }],
      pe = {
        "1.9.0": [{
          capability: {
            location: {}
          },
          hostClientTypes: ue
        }],
        "2.0.0": [{
          capability: {
            people: {}
          },
          hostClientTypes: ue
        }, {
          capability: {
            sharing: {}
          },
          hostClientTypes: [N.desktop, N.web]
        }],
        "2.0.1": [{
          capability: {
            teams: {
              fullTrust: {
                joinedTeams: {}
              }
            }
          },
          hostClientTypes: [N.android, N.desktop, N.ios, N.teamsRoomsAndroid, N.teamsPhones, N.teamsDisplays, N.web]
        }, {
          capability: {
            webStorage: {}
          },
          hostClientTypes: [N.desktop]
        }],
        "2.0.5": [{
          capability: {
            webStorage: {}
          },
          hostClientTypes: [N.android, N.desktop, N.ios]
        }]
      },
      ge = oe.extend("generateBackCompatRuntimeConfig");
    function me(e) {
      ge("generating back compat runtime config for %s", e);
      var n = te({}, ce.supports);
      ge("Supported capabilities in config before updating based on highestSupportedVersion: %o", n), Object.keys(pe).forEach(function (t) {
        K(e, t) >= 0 && pe[t].forEach(function (e) {
          e.hostClientTypes.includes(v.hostClientType) && (n = te(te({}, n), e.capability));
        });
      });
      var t = {
        apiVersion: 2,
        isLegacyTeams: !0,
        supports: n
      };
      return ge("Runtime config after updating based on highestSupportedVersion: %o", t), t;
    }
    var he = oe.extend("applyRuntimeConfig");
    function we(e) {
      "string" == typeof e.apiVersion && (he("Trying to apply runtime with string apiVersion, processing as v1: %o", e), e = te(te({}, e), {
        apiVersion: 1
      })), he("Fast-forwarding runtime %o", e);
      var n = de(e);
      he("Applying runtime %o", n), le = J(n);
    }
    var Ce,
      ve,
      ye,
      Se,
      be,
      Te,
      Ie = "2.10.0",
      Ee = S("internal"),
      Pe = Ee.extend("ensureInitializeCalled"),
      Fe = Ee.extend("ensureInitialized");
    function ke() {
      if (!v.initializeCalled) throw Pe(h), new Error(h);
    }
    function Ae(e) {
      for (var n = [], t = 1; t < arguments.length; t++) n[t - 1] = arguments[t];
      if (!v.initializeCompleted) throw Fe("%s. initializeCalled: %s", h, v.initializeCalled.toString()), new Error(h);
      if (n && n.length > 0) {
        for (var o = !1, i = 0; i < n.length; i++) if (n[i] === v.frameContext) {
          o = !0;
          break;
        }
        if (!o) throw new Error("This call is only allowed in following contexts: ".concat(JSON.stringify(n), ". ") + 'Current context: "'.concat(v.frameContext, '".'));
      }
      return se(e);
    }
    function Re(n) {
      void 0 === n && (n = e);
      var t = K(v.clientSupportedSDKVersion, n);
      return !isNaN(t) && t >= 0;
    }
    function Oe() {
      return v.hostClientType == N.android || v.hostClientType == N.ios || v.hostClientType == N.ipados;
    }
    function Me(n) {
      if (void 0 === n && (n = e), !Oe()) throw {
        errorCode: M.NOT_SUPPORTED_ON_PLATFORM
      };
      if (!Re(n)) throw {
        errorCode: M.OLD_PLATFORM
      };
    }
    function De(e) {
      var n = v.additionalValidOrigins.concat(e.filter(function (e) {
          return "string" == typeof e && p.test(e);
        })),
        t = {};
      n = n.filter(function (e) {
        return !t[e] && (t[e] = !0, !0);
      }), v.additionalValidOrigins = n;
    }
    function Ne(e) {
      return {
        actionInfo: e.actionInfo,
        app: {
          locale: e.locale,
          sessionId: e.appSessionId ? e.appSessionId : "",
          theme: e.theme ? e.theme : "default",
          iconPositionVertical: e.appIconPosition,
          osLocaleInfo: e.osLocaleInfo,
          parentMessageId: e.parentMessageId,
          userClickTime: e.userClickTime,
          userFileOpenPreference: e.userFileOpenPreference,
          host: {
            name: e.hostName ? e.hostName : L.teams,
            clientType: e.hostClientType ? e.hostClientType : N.web,
            sessionId: e.sessionId ? e.sessionId : "",
            ringId: e.ringId
          },
          appLaunchId: e.appLaunchId
        },
        page: {
          id: e.entityId,
          frameContext: e.frameContext ? e.frameContext : v.frameContext,
          subPageId: e.subEntityId,
          isFullScreen: e.isFullScreen,
          isMultiWindow: e.isMultiWindow,
          sourceOrigin: e.sourceOrigin
        },
        user: {
          id: e.userObjectId,
          displayName: e.userDisplayName,
          isCallingAllowed: e.isCallingAllowed,
          isPSTNCallingAllowed: e.isPSTNCallingAllowed,
          licenseType: e.userLicenseType,
          loginHint: e.loginHint,
          userPrincipalName: e.userPrincipalName,
          tenant: e.tid ? {
            id: e.tid,
            teamsSku: e.tenantSKU
          } : void 0
        },
        channel: e.channelId ? {
          id: e.channelId,
          displayName: e.channelName,
          relativeUrl: e.channelRelativeUrl,
          membershipType: e.channelType,
          defaultOneNoteSectionId: e.defaultOneNoteSectionId,
          ownerGroupId: e.hostTeamGroupId,
          ownerTenantId: e.hostTeamTenantId
        } : void 0,
        chat: e.chatId ? {
          id: e.chatId
        } : void 0,
        meeting: e.meetingId ? {
          id: e.meetingId
        } : void 0,
        sharepoint: e.sharepoint,
        team: e.teamId ? {
          internalId: e.teamId,
          displayName: e.teamName,
          type: e.teamType,
          groupId: e.groupId,
          templateId: e.teamTemplateId,
          isArchived: e.isTeamArchived,
          userRole: e.userTeamRole
        } : void 0,
        sharePointSite: e.teamSiteUrl || e.teamSiteDomain || e.teamSitePath || e.mySitePath || e.mySiteDomain ? {
          teamSiteUrl: e.teamSiteUrl,
          teamSiteDomain: e.teamSiteDomain,
          teamSitePath: e.teamSitePath,
          teamSiteId: e.teamSiteId,
          mySitePath: e.mySitePath,
          mySiteDomain: e.mySiteDomain
        } : void 0
      };
    }
    function Le() {
      return "undefined" == typeof window;
    }
    !function (e) {
      var n, t, o;
      function i(e) {
        return new Promise(function (t, o) {
          if (v.hostClientType === N.desktop || v.hostClientType === N.android || v.hostClientType === N.ios || v.hostClientType === N.ipados || v.hostClientType === N.rigel || v.hostClientType === N.teamsRoomsWindows || v.hostClientType === N.teamsRoomsAndroid || v.hostClientType === N.teamsPhones || v.hostClientType === N.teamsDisplays) {
            var i = document.createElement("a");
            i.href = e.url, t(cn("authentication.authenticate", [i.href, e.width, e.height, e.isExternal]).then(function (e) {
              var n = e[0],
                t = e[1];
              if (n) return t;
              throw new Error(t);
            }));
          } else n = {
            success: t,
            fail: o
          }, l(e);
        });
      }
      function r(e) {
        return new Promise(function (n) {
          n(cn("authentication.getAuthToken", [null == e ? void 0 : e.resources, null == e ? void 0 : e.claims, null == e ? void 0 : e.silent]));
        }).then(function (e) {
          var n = e[0],
            t = e[1];
          if (n) return t;
          throw new Error(t);
        });
      }
      function a() {
        return new Promise(function (e) {
          e(cn("authentication.getUser"));
        }).then(function (e) {
          var n = e[0],
            t = e[1];
          if (n) return t;
          throw new Error(t);
        });
      }
      function s() {
        c();
        try {
          en.childWindow && en.childWindow.close();
        } finally {
          en.childWindow = null, en.childOrigin = null;
        }
      }
      function l(e) {
        s();
        var n = e.width || 600,
          t = e.height || 400;
        n = Math.min(n, en.currentWindow.outerWidth - 400), t = Math.min(t, en.currentWindow.outerHeight - 200);
        var o = document.createElement("a");
        o.href = e.url.replace("{oauthRedirectMethod}", "web");
        var i = void 0 !== en.currentWindow.screenLeft ? en.currentWindow.screenLeft : en.currentWindow.screenX,
          r = void 0 !== en.currentWindow.screenTop ? en.currentWindow.screenTop : en.currentWindow.screenY;
        i += en.currentWindow.outerWidth / 2 - n / 2, r += en.currentWindow.outerHeight / 2 - t / 2, en.childWindow = en.currentWindow.open(o.href, "_blank", "toolbar=no, location=yes, status=no, menubar=no, scrollbars=yes, top=" + r + ", left=" + i + ", width=" + n + ", height=" + t), en.childWindow ? u() : f("FailedToOpenWindow");
      }
      function c() {
        t && (clearInterval(t), t = 0), ze("initialize"), ze("navigateCrossDomain");
      }
      function u() {
        c(), t = en.currentWindow.setInterval(function () {
          if (!en.childWindow || en.childWindow.closed) f("CancelledByUser");else {
            var e = en.childOrigin;
            try {
              en.childOrigin = "*", Mn("ping");
            } finally {
              en.childOrigin = e;
            }
          }
        }, 100), Be("initialize", function () {
          return [U.authentication, v.hostClientType];
        }), Be("navigateCrossDomain", function () {
          return !1;
        });
      }
      function d(e) {
        try {
          n && n.success(e);
        } finally {
          n = null, s();
        }
      }
      function f(e) {
        try {
          n && n.fail(new Error(e));
        } finally {
          n = null, s();
        }
      }
      function p(e, n, t) {
        if (e) {
          var o = document.createElement("a");
          o.href = decodeURIComponent(e), o.host && o.host !== window.location.host && "outlook.office.com" === o.host && o.search.indexOf("client_type=Win32_Outlook") > -1 && (n && "result" === n && (t && (o.href = g(o.href, "result", t)), en.currentWindow.location.assign(g(o.href, "authSuccess", ""))), n && "reason" === n && (t && (o.href = g(o.href, "reason", t)), en.currentWindow.location.assign(g(o.href, "authFailure", ""))));
        }
      }
      function g(e, n, t) {
        var o = e.indexOf("#"),
          i = -1 === o ? "#" : e.substr(o);
        return i = i + "&" + n + ("" !== t ? "=" + t : ""), (e = -1 === o ? e : e.substr(0, o)) + i;
      }
      e.initialize = function () {
        Be("authentication.authenticate.success", d, !1), Be("authentication.authenticate.failure", f, !1);
      }, e.registerAuthenticationHandlers = function (e) {
        o = e;
      }, e.authenticate = function (e) {
        var n = void 0 !== e,
          t = n ? e : o;
        if (!t) throw new Error("No parameters are provided for authentication");
        return Ae(le, U.content, U.sidePanel, U.settings, U.remove, U.task, U.stage, U.meetingStage), i(t).then(function (e) {
          try {
            return t && t.successCallback ? (t.successCallback(e), "") : e;
          } finally {
            n || (o = null);
          }
        }).catch(function (e) {
          try {
            if (t && t.failureCallback) return t.failureCallback(e.message), "";
            throw e;
          } finally {
            n || (o = null);
          }
        });
      }, e.getAuthToken = function (e) {
        return ke(), r(e).then(function (n) {
          return e && e.successCallback ? (e.successCallback(n), "") : n;
        }).catch(function (n) {
          if (e && e.failureCallback) return e.failureCallback(n.message), "";
          throw n;
        });
      }, e.getUser = function (e) {
        return ke(), a().then(function (n) {
          return e && e.successCallback ? (e.successCallback(n), null) : n;
        }).catch(function (n) {
          if (e && e.failureCallback) return e.failureCallback(n.message), null;
          throw n;
        });
      }, e.notifySuccess = function (e, n) {
        p(n, "result", e), Ae(le, U.authentication), dn("authentication.authenticate.success", [e]), Rn(en.parentWindow, function () {
          return setTimeout(function () {
            return en.currentWindow.close();
          }, 200);
        });
      }, e.notifyFailure = function (e, n) {
        p(n, "reason", e), Ae(le, U.authentication), dn("authentication.authenticate.failure", [e]), Rn(en.parentWindow, function () {
          return setTimeout(function () {
            return en.currentWindow.close();
          }, 200);
        });
      }, function (e) {
        e.Public = "public", e.EUDB = "eudb", e.Other = "other";
      }(e.DataResidency || (e.DataResidency = {}));
    }(Ce || (Ce = {})), function (e) {
      var n = [];
      function t(e) {
        v.frameContext && (v.frameContext === U.task ? n.push(e) : ze("messageForChild"));
      }
      e.initialize = function () {
        Be("messageForChild", t, !1);
      }, function (e) {
        function t() {
          return Ae(le) && void 0 !== (le.supports.dialog && le.supports.dialog.url);
        }
        function o(e) {
          return {
            url: e.url,
            height: e.size ? e.size.height : W.Small,
            width: e.size ? e.size.width : W.Small,
            title: e.title,
            fallbackUrl: e.fallbackUrl
          };
        }
        function i(e) {
          var n = o(e);
          return n.completionBotId = e.completionBotId, n;
        }
        e.open = function (e, n, i) {
          if (Ae(le, U.content, U.sidePanel, U.meetingStage), !t()) throw B;
          i && Be("messageForParent", i), dn("tasks.startTask", [o(e)], function (e, t) {
            null == n || n({
              err: e,
              result: t
            }), ze("messageForParent");
          });
        }, e.submit = function (e, n) {
          if (Ae(le, U.content, U.task), !t()) throw B;
          dn("tasks.completeTask", [e, n ? Array.isArray(n) ? n : [n] : []]);
        }, e.sendMessageToParentFromDialog = function (e) {
          if (Ae(le, U.task), !t()) throw B;
          dn("messageForParent", [e]);
        }, e.sendMessageToDialog = function (e) {
          if (Ae(le, U.content, U.sidePanel, U.meetingStage), !t()) throw B;
          dn("messageForChild", [e]);
        }, e.registerOnMessageFromParent = function (e) {
          if (Ae(le, U.task), !t()) throw B;
          for (ze("messageForChild"), Be("messageForChild", e), n.reverse(); n.length > 0;) {
            e(n.pop());
          }
        }, e.isSupported = t, function (e) {
          function n() {
            return Ae(le) && void 0 !== (le.supports.dialog && le.supports.dialog.url && le.supports.dialog.url.bot);
          }
          e.open = function (e, t, o) {
            if (Ae(le, U.content, U.sidePanel, U.meetingStage), !n()) throw B;
            o && Be("messageForParent", o), dn("tasks.startTask", [i(e)], function (e, n) {
              null == t || t({
                err: e,
                result: n
              }), ze("messageForParent");
            });
          }, e.isSupported = n;
        }(e.bot || (e.bot = {})), e.getDialogInfoFromUrlDialogInfo = o, e.getDialogInfoFromBotUrlDialogInfo = i;
      }(e.url || (e.url = {})), e.isSupported = function () {
        return !(!Ae(le) || !le.supports.dialog);
      }, function (e) {
        function n() {
          return !(!Ae(le) || !le.supports.dialog) && !!le.supports.dialog.update;
        }
        e.resize = function (e) {
          if (Ae(le, U.content, U.sidePanel, U.task, U.meetingStage), !n()) throw B;
          dn("tasks.updateTask", [e]);
        }, e.isSupported = n;
      }(e.update || (e.update = {})), function (e) {
        function n() {
          var e = le.hostVersionsInfo && le.hostVersionsInfo.adaptiveCardSchemaVersion && !ne(le.hostVersionsInfo.adaptiveCardSchemaVersion);
          return Ae(le) && void 0 !== (e && le.supports.dialog && le.supports.dialog.card);
        }
        function t(e) {
          return {
            card: e.card,
            height: e.size ? e.size.height : W.Small,
            width: e.size ? e.size.width : W.Small,
            title: e.title
          };
        }
        function o(e) {
          var n = t(e);
          return n.completionBotId = e.completionBotId, n;
        }
        e.open = function (e, o) {
          if (Ae(le, U.content, U.sidePanel, U.meetingStage), !n()) throw B;
          dn("tasks.startTask", [t(e)], function (e, n) {
            null == o || o({
              err: e,
              result: n
            });
          });
        }, e.isSupported = n, function (e) {
          function n() {
            var e = le.hostVersionsInfo && le.hostVersionsInfo.adaptiveCardSchemaVersion && !ne(le.hostVersionsInfo.adaptiveCardSchemaVersion);
            return Ae(le) && void 0 !== (e && le.supports.dialog && le.supports.dialog.card && le.supports.dialog.card.bot);
          }
          e.open = function (e, t) {
            if (Ae(le, U.content, U.sidePanel, U.meetingStage), !n()) throw B;
            dn("tasks.startTask", [o(e)], function (e, n) {
              null == t || t({
                err: e,
                result: n
              });
            });
          }, e.isSupported = n;
        }(e.bot || (e.bot = {})), e.getDialogInfoFromAdaptiveCardDialogInfo = t, e.getDialogInfoFromBotAdaptiveCardDialogInfo = o, e.getAdaptiveCardDialogInfoFromTaskInfo = function (e) {
          return {
            card: e.card,
            size: {
              height: e.height ? e.height : W.Small,
              width: e.width ? e.width : W.Small
            },
            title: e.title
          };
        }, e.getBotAdaptiveCardDialogInfoFromTaskInfo = function (e) {
          return {
            card: e.card,
            size: {
              height: e.height ? e.height : W.Small,
              width: e.width ? e.width : W.Small
            },
            title: e.title,
            completionBotId: e.completionBotId
          };
        };
      }(e.adaptiveCard || (e.adaptiveCard = {}));
    }(ve || (ve = {})), function (e) {
      !function (e) {
        e[e.ifRoom = 0] = "ifRoom", e[e.overflowOnly = 1] = "overflowOnly";
      }(e.DisplayMode || (e.DisplayMode = {}));
      var n,
        t,
        o,
        i = function () {
          return function () {
            this.enabled = !0, this.selected = !1;
          };
        }();
      function r(e) {
        o && o(e) || (Ae(le), dn("viewConfigItemPress", [e]));
      }
      function a(e) {
        n && n(e) || (Ae(le), dn("handleNavBarMenuItemPress", [e]));
      }
      function s(e) {
        t && t(e) || (Ae(le), dn("handleActionMenuItemPress", [e]));
      }
      function l() {
        return !(!Ae(le) || !le.supports.menus);
      }
      e.MenuItem = i, function (e) {
        e.dropDown = "dropDown", e.popOver = "popOver";
      }(e.MenuListType || (e.MenuListType = {})), e.initialize = function () {
        Be("navBarMenuItemPress", a, !1), Be("actionMenuItemPress", s, !1), Be("setModuleView", r, !1);
      }, e.setUpViews = function (e, n) {
        if (Ae(le), !l()) throw B;
        o = n, dn("setUpViews", [e]);
      }, e.setNavBarMenu = function (e, t) {
        if (Ae(le), !l()) throw B;
        n = t, dn("setNavBarMenu", [e]);
      }, e.showActionMenu = function (e, n) {
        if (Ae(le), !l()) throw B;
        t = n, dn("showActionMenu", [e]);
      }, e.isSupported = l;
    }(ye || (ye = {})), function (e) {
      function n() {
        if ("undefined" == typeof window) throw new Error("window object undefined at print call");
        window.print();
      }
      function t(e, n) {
        e && Ae(le), e && n && n(), Ze(e);
      }
      function o(e, n) {
        e && Ae(le), e && n && n(), Qe(e);
      }
      function i() {
        return !(!Ae(le) || !le.supports.teamsCore);
      }
      e.enablePrintCapability = function () {
        if (!v.printCapabilityEnabled) {
          if (Ae(le), !i()) throw B;
          v.printCapabilityEnabled = !0, document.addEventListener("keydown", function (e) {
            (e.ctrlKey || e.metaKey) && 80 === e.keyCode && (n(), e.cancelBubble = !0, e.preventDefault(), e.stopImmediatePropagation());
          });
        }
      }, e.print = n, e.registerOnLoadHandler = function (e) {
        t(e, function () {
          if (e && !i()) throw B;
        });
      }, e.registerOnLoadHandlerHelper = t, e.registerBeforeUnloadHandler = function (e) {
        o(e, function () {
          if (e && !i()) throw B;
        });
      }, e.registerBeforeUnloadHandlerHelper = o, e.isSupported = i;
    }(Se || (Se = {})), function (n) {
      var t = S("app");
      n.Messages = {
        AppLoaded: "appInitialization.appLoaded",
        Success: "appInitialization.success",
        Failure: "appInitialization.failure",
        ExpectedFailure: "appInitialization.expectedFailure"
      }, function (e) {
        e.AuthFailed = "AuthFailed", e.Timeout = "Timeout", e.Other = "Other";
      }(n.FailedReason || (n.FailedReason = {})), function (e) {
        e.PermissionError = "PermissionError", e.NotFound = "NotFound", e.Throttling = "Throttling", e.Offline = "Offline", e.Other = "Other";
      }(n.ExpectedFailureReason || (n.ExpectedFailureReason = {})), n.isInitialized = function () {
        return v.initializeCompleted;
      }, n.getFrameContext = function () {
        return v.frameContext;
      };
      var o = 5e3;
      n.initialize = function (e) {
        return Le() ? (t.extend("initialize")("window object undefined at initialization"), Promise.resolve()) : Y(function () {
          return r(e);
        }, o, new Error("SDK initialization timed out."));
      };
      var i = t.extend("initializeHelper");
      function r(n) {
        return new Promise(function (t) {
          v.initializeCalled || (v.initializeCalled = !0, We(), v.initializePromise = tn(n).then(function (n) {
            var t = n.context,
              o = n.clientType,
              r = n.runtimeConfig,
              a = n.clientSupportedSDKVersion,
              s = void 0 === a ? e : a;
            v.frameContext = t, v.hostClientType = o, v.clientSupportedSDKVersion = s;
            try {
              i("Parsing %s", r);
              var l = JSON.parse(r);
              if (i("Checking if %o is a valid runtime object", null != l ? l : "null"), !l || !l.apiVersion) throw new Error("Received runtime config is invalid");
              r && we(l);
            } catch (n) {
              if (!(n instanceof SyntaxError)) throw n;
              try {
                i("Attempting to parse %s as an SDK version", r), isNaN(K(r, e)) || (v.clientSupportedSDKVersion = r);
                l = JSON.parse(s);
                if (i("givenRuntimeConfig parsed to %o", null != l ? l : "null"), !l) throw new Error("givenRuntimeConfig string was successfully parsed. However, it parsed to value of null");
                we(l);
              } catch (e) {
                if (!(e instanceof SyntaxError)) throw e;
                we(me(v.clientSupportedSDKVersion));
              }
            }
            v.initializeCompleted = !0;
          }), Ce.initialize(), ye.initialize(), Te.config.initialize(), ve.initialize()), Array.isArray(n) && De(n), t(v.initializePromise);
        });
      }
      function a(e) {
        e && ke(), qe(e);
      }
      n._initialize = function (e) {
        en.currentWindow = e;
      }, n._uninitialize = function () {
        v.initializeCalled && (v.frameContext && (a(null), Te.backStack.registerBackButtonHandler(null), Te.registerFullScreenHandler(null), Se.registerBeforeUnloadHandler(null), Se.registerOnLoadHandler(null), Tn.registerGetLogHandler(null)), v.frameContext === U.settings && Te.config.registerOnSaveHandler(null), v.frameContext === U.remove && Te.config.registerOnRemoveHandler(null), v.initializeCalled = !1, v.initializeCompleted = !1, v.initializePromise = null, v.additionalValidOrigins = [], v.frameContext = null, v.hostClientType = null, v.isFramelessWindow = !1, on());
      }, n.getContext = function () {
        return new Promise(function (e) {
          ke(), e(rn("getContext"));
        }).then(function (e) {
          return Ne(e);
        });
      }, n.notifyAppLoaded = function () {
        ke(), dn(n.Messages.AppLoaded, [Ie]);
      }, n.notifySuccess = function () {
        ke(), dn(n.Messages.Success, [Ie]);
      }, n.notifyFailure = function (e) {
        ke(), dn(n.Messages.Failure, [e.reason, e.message]);
      }, n.notifyExpectedFailure = function (e) {
        ke(), dn(n.Messages.ExpectedFailure, [e.reason, e.message]);
      }, n.registerOnThemeChangeHandler = a, n.openLink = function (e) {
        return new Promise(function (n) {
          Ae(le, U.content, U.sidePanel, U.settings, U.task, U.stage, U.meetingStage), n(an("executeDeepLink", e));
        });
      };
    }(be || (be = {})), function (e) {
      function n(e) {
        if (Ae(le, U.content), !t()) throw B;
        dn("setFrameContext", [e]);
      }
      function t() {
        return !(!Ae(le) || !le.supports.pages);
      }
      e.returnFocus = function (e) {
        if (Ae(le), !t()) throw B;
        dn("returnFocus", [e]);
      }, e.registerFocusEnterHandler = function (e) {
        Ge("focusEnter", e, [], function () {
          if (!t()) throw B;
        });
      }, e.setCurrentFrame = n, e.initializeWithFrameContext = function (e, t, o) {
        be.initialize(o).then(function () {
          return t && t();
        }), n(e);
      }, e.getConfig = function () {
        return new Promise(function (e) {
          if (Ae(le, U.content, U.settings, U.remove, U.sidePanel), !t()) throw B;
          e(rn("settings.getSettings"));
        });
      }, e.navigateCrossDomain = function (e) {
        return new Promise(function (n) {
          if (Ae(le, U.content, U.sidePanel, U.settings, U.remove, U.task, U.stage, U.meetingStage), !t()) throw B;
          n(sn("navigateCrossDomain", "Cross-origin navigation is only supported for URLs matching the pattern registered in the manifest.", e));
        });
      }, e.navigateToApp = function (e) {
        return new Promise(function (n) {
          if (Ae(le, U.content, U.sidePanel, U.settings, U.task, U.stage, U.meetingStage), !t()) throw B;
          le.isLegacyTeams ? n(an("executeDeepLink", ee(e))) : n(an("pages.navigateToApp", e));
        });
      }, e.shareDeepLink = function (e) {
        if (Ae(le, U.content, U.sidePanel, U.meetingStage), !t()) throw B;
        dn("shareDeepLink", [e.subPageId, e.subPageLabel, e.subPageWebUrl]);
      }, e.registerFullScreenHandler = function (e) {
        Ge("fullScreenChange", e, [], function () {
          if (e && !t()) throw B;
        });
      }, e.isSupported = t, function (e) {
        function n() {
          return !(!Ae(le) || !le.supports.pages) && !!le.supports.pages.tabs;
        }
        e.navigateToTab = function (e) {
          return new Promise(function (t) {
            if (Ae(le), !n()) throw B;
            t(sn("navigateToTab", "Invalid internalTabInstanceId and/or channelId were/was provided", e));
          });
        }, e.getTabInstances = function (e) {
          return new Promise(function (t) {
            if (Ae(le), !n()) throw B;
            t(rn("getTabInstances", e));
          });
        }, e.getMruTabInstances = function (e) {
          return new Promise(function (t) {
            if (Ae(le), !n()) throw B;
            t(rn("getMruTabInstances", e));
          });
        }, e.isSupported = n;
      }(e.tabs || (e.tabs = {})), function (e) {
        var n, t;
        function o(e, t) {
          e && Ae(le, U.settings), t && t(), n = e, e && dn("registerHandler", ["save"]);
        }
        function i(e, n) {
          e && Ae(le, U.remove, U.settings), n && n(), t = e, e && dn("registerHandler", ["remove"]);
        }
        function r(e) {
          var t = new a(e);
          n ? n(t) : en.childWindow ? Mn("settings.save", [e]) : t.notifySuccess();
        }
        e.initialize = function () {
          Be("settings.save", r, !1), Be("settings.remove", s, !1);
        }, e.setValidityState = function (e) {
          if (Ae(le, U.settings, U.remove), !c()) throw B;
          dn("settings.setValidityState", [e]);
        }, e.setConfig = function (e) {
          return new Promise(function (n) {
            if (Ae(le, U.content, U.settings, U.sidePanel), !c()) throw B;
            n(an("settings.setSettings", e));
          });
        }, e.registerOnSaveHandler = function (e) {
          o(e, function () {
            if (e && !c()) throw B;
          });
        }, e.registerOnSaveHandlerHelper = o, e.registerOnRemoveHandler = function (e) {
          i(e, function () {
            if (e && !c()) throw B;
          });
        }, e.registerOnRemoveHandlerHelper = i, e.registerChangeConfigHandler = function (e) {
          Ge("changeSettings", e, [U.content], function () {
            if (!c()) throw B;
          });
        };
        var a = function () {
          function e(e) {
            this.notified = !1, this.result = e || {};
          }
          return e.prototype.notifySuccess = function () {
            this.ensureNotNotified(), dn("settings.save.success"), this.notified = !0;
          }, e.prototype.notifyFailure = function (e) {
            this.ensureNotNotified(), dn("settings.save.failure", [e]), this.notified = !0;
          }, e.prototype.ensureNotNotified = function () {
            if (this.notified) throw new Error("The SaveEvent may only notify success or failure once.");
          }, e;
        }();
        function s() {
          var e = new l();
          t ? t(e) : en.childWindow ? Mn("settings.remove", []) : e.notifySuccess();
        }
        var l = function () {
          function e() {
            this.notified = !1;
          }
          return e.prototype.notifySuccess = function () {
            this.ensureNotNotified(), dn("settings.remove.success"), this.notified = !0;
          }, e.prototype.notifyFailure = function (e) {
            this.ensureNotNotified(), dn("settings.remove.failure", [e]), this.notified = !0;
          }, e.prototype.ensureNotNotified = function () {
            if (this.notified) throw new Error("The removeEvent may only notify success or failure once.");
          }, e;
        }();
        function c() {
          return !(!Ae(le) || !le.supports.pages) && !!le.supports.pages.config;
        }
        e.isSupported = c;
      }(e.config || (e.config = {})), function (e) {
        var n;
        function t() {
          return new Promise(function (e) {
            if (Ae(le), !r()) throw B;
            e(sn("navigateBack", "Back navigation is not supported in the current client or context."));
          });
        }
        function o(e, t) {
          e && Ae(le), t && t(), n = e, e && dn("registerHandler", ["backButton"]);
        }
        function i() {
          n && n() || (en.childWindow ? Mn("backButtonPress", []) : t());
        }
        function r() {
          return !(!Ae(le) || !le.supports.pages) && !!le.supports.pages.backStack;
        }
        e._initialize = function () {
          Be("backButtonPress", i, !1);
        }, e.navigateBack = t, e.registerBackButtonHandler = function (e) {
          o(e, function () {
            if (e && !r()) throw B;
          });
        }, e.registerBackButtonHandlerHelper = o, e.isSupported = r;
      }(e.backStack || (e.backStack = {})), function (e) {
        function n() {
          return !(!Ae(le) || !le.supports.pages) && !!le.supports.pages.fullTrust;
        }
        e.enterFullscreen = function () {
          if (Ae(le, U.content), !n()) throw B;
          dn("enterFullscreen", []);
        }, e.exitFullscreen = function () {
          if (Ae(le, U.content), !n()) throw B;
          dn("exitFullscreen", []);
        }, e.isSupported = n;
      }(e.fullTrust || (e.fullTrust = {})), function (e) {
        function n() {
          return !(!Ae(le) || !le.supports.pages) && !!le.supports.pages.appButton;
        }
        e.onClick = function (e) {
          Ge("appButtonClick", e, [U.content], function () {
            if (!n()) throw B;
          });
        }, e.onHoverEnter = function (e) {
          Ge("appButtonHoverEnter", e, [U.content], function () {
            if (!n()) throw B;
          });
        }, e.onHoverLeave = function (e) {
          Ge("appButtonHoverLeave", e, [U.content], function () {
            if (!n()) throw B;
          });
        }, e.isSupported = n;
      }(e.appButton || (e.appButton = {})), function (e) {
        function n() {
          return !(!Ae(le) || !le.supports.pages) && !!le.supports.pages.currentApp;
        }
        e.navigateTo = function (e) {
          return new Promise(function (t) {
            if (Ae(le, U.content, U.sidePanel, U.settings, U.task, U.stage, U.meetingStage), !n()) throw B;
            t(ln("pages.currentApp.navigateTo", e));
          });
        }, e.navigateToDefaultPage = function () {
          return new Promise(function (e) {
            if (Ae(le, U.content, U.sidePanel, U.settings, U.task, U.stage, U.meetingStage), !n()) throw B;
            e(ln("pages.currentApp.navigateToDefaultPage"));
          });
        }, e.isSupported = n;
      }(e.currentApp || (e.currentApp = {}));
    }(Te || (Te = {}));
    var Ue = function (e, n, t) {
        if (t || 2 === arguments.length) for (var o, i = 0, r = n.length; i < r; i++) !o && i in n || (o || (o = Array.prototype.slice.call(n, 0, i)), o[i] = n[i]);
        return e.concat(o || Array.prototype.slice.call(n));
      },
      He = S("handlers"),
      Ve = function () {
        function e() {}
        return e.handlers = {}, e;
      }();
    function We() {
      Ve.handlers.themeChange = Ke, Ve.handlers.load = Je, Ve.handlers.beforeUnload = Xe, Te.backStack._initialize();
    }
    var xe = He.extend("callHandler");
    function _e(e, n) {
      var t = Ve.handlers[e];
      return t ? (xe("Invoking the registered handler for message %s with arguments %o", e, n), [!0, t.apply(this, n)]) : en.childWindow ? (Mn(e, [n]), [!1, void 0]) : (xe("Handler for action message %s not found.", e), [!1, void 0]);
    }
    function Be(e, n, t, o) {
      void 0 === t && (t = !0), void 0 === o && (o = []), n ? (Ve.handlers[e] = n, t && dn("registerHandler", Ue([e], o, !0))) : delete Ve.handlers[e];
    }
    function ze(e) {
      delete Ve.handlers[e];
    }
    function je(e) {
      return null != Ve.handlers[e];
    }
    function Ge(e, n, t, o) {
      n && Ae.apply(void 0, Ue([le], t, !1)), o && o(), Be(e, n);
    }
    function qe(e) {
      Ve.themeChangeHandler = e, e && dn("registerHandler", ["themeChange"]);
    }
    function Ke(e) {
      Ve.themeChangeHandler && Ve.themeChangeHandler(e), en.childWindow && Mn("themeChange", [e]);
    }
    function Ze(e) {
      Ve.loadHandler = e, e && dn("registerHandler", ["load"]);
    }
    function Je(e) {
      Ve.loadHandler && Ve.loadHandler(e), en.childWindow && Mn("load", [e]);
    }
    function Qe(e) {
      Ve.beforeUnloadHandler = e, e && dn("registerHandler", ["beforeUnload"]);
    }
    function Xe() {
      var e = function () {
        dn("readyToUnload", []);
      };
      Ve.beforeUnloadHandler && Ve.beforeUnloadHandler(e) || (en.childWindow ? Mn("beforeUnload") : e());
    }
    var $e = function (e, n, t) {
        if (t || 2 === arguments.length) for (var o, i = 0, r = n.length; i < r; i++) !o && i in n || (o || (o = Array.prototype.slice.call(n, 0, i)), o[i] = n[i]);
        return e.concat(o || Array.prototype.slice.call(n));
      },
      Ye = S("communication"),
      en = function () {
        return function () {};
      }(),
      nn = function () {
        function e() {}
        return e.parentMessageQueue = [], e.childMessageQueue = [], e.nextMessageId = 0, e.callbacks = {}, e.promiseCallbacks = {}, e;
      }();
    function tn(e) {
      if (nn.messageListener = function (e) {
        return gn(e);
      }, en.currentWindow = en.currentWindow || window, en.parentWindow = en.currentWindow.parent !== en.currentWindow.self ? en.currentWindow.parent : en.currentWindow.opener, (en.parentWindow || e) && en.currentWindow.addEventListener("message", nn.messageListener, !1), !en.parentWindow) {
        var n = en.currentWindow;
        if (!n.nativeInterface) return Promise.reject(new Error("Initialization Failed. No Parent window found."));
        v.isFramelessWindow = !0, n.onNativeMessage = Cn;
      }
      try {
        return en.parentOrigin = "*", cn("initialize", [Ie, ie]).then(function (e) {
          return {
            context: e[0],
            clientType: e[1],
            runtimeConfig: e[2],
            clientSupportedSDKVersion: e[3]
          };
        });
      } finally {
        en.parentOrigin = null;
      }
    }
    function on() {
      en.currentWindow && en.currentWindow.removeEventListener("message", nn.messageListener, !1), en.currentWindow = null, en.parentWindow = null, en.parentOrigin = null, en.childWindow = null, en.childOrigin = null, nn.parentMessageQueue = [], nn.childMessageQueue = [], nn.nextMessageId = 0, nn.callbacks = {}, nn.promiseCallbacks = {};
    }
    function rn(e) {
      for (var n = [], t = 1; t < arguments.length; t++) n[t - 1] = arguments[t];
      return cn(e, n).then(function (e) {
        return e[0];
      });
    }
    function an(e) {
      for (var n = [], t = 1; t < arguments.length; t++) n[t - 1] = arguments[t];
      return cn(e, n).then(function (e) {
        var n = e[0],
          t = e[1];
        if (!n) throw new Error(t);
      });
    }
    function sn(e, n) {
      for (var t = [], o = 2; o < arguments.length; o++) t[o - 2] = arguments[o];
      return cn(e, t).then(function (e) {
        var t = e[0],
          o = e[1];
        if (!t) throw new Error(o || n);
      });
    }
    function ln(e) {
      for (var n = [], t = 1; t < arguments.length; t++) n[t - 1] = arguments[t];
      return cn(e, n).then(function (e) {
        var n = e[0],
          t = e[1];
        if (n) throw n;
        return t;
      });
    }
    function cn(e, n) {
      return void 0 === n && (n = void 0), new Promise(function (t) {
        t(un(pn(e, n).id));
      });
    }
    function un(e) {
      return new Promise(function (n) {
        nn.promiseCallbacks[e] = n;
      });
    }
    function dn(e, n, t) {
      var o;
      n instanceof Function ? t = n : n instanceof Array && (o = n);
      var i = pn(e, o);
      t && (nn.callbacks[i.id] = t);
    }
    var fn = Ye.extend("sendMessageToParentHelper");
    function pn(e, n) {
      var t = fn,
        o = en.parentWindow,
        i = Dn(e, n);
      if (t("Message %i information: %o", i.id, {
        actionName: e,
        args: n
      }), v.isFramelessWindow) en.currentWindow && en.currentWindow.nativeInterface && (t("Sending message %i to parent via framelessPostMessage interface", i.id), en.currentWindow.nativeInterface.framelessPostMessage(JSON.stringify(i)));else {
        var r = bn(o);
        o && r ? (t("Sending message %i to parent via postMessage", i.id), o.postMessage(i, r)) : (t("Adding message %i to parent message queue", i.id), Sn(o).push(i));
      }
      return i;
    }
    function gn(e) {
      if (e && e.data && "object" == typeof e.data) {
        var n = e.source || e.originalEvent && e.originalEvent.source,
          t = e.origin || e.originalEvent && e.originalEvent.origin;
        mn(n, t) && (hn(n, t), n === en.parentWindow ? Cn(e) : n === en.childWindow && yn(e));
      }
    }
    function mn(e, n) {
      return (!en.currentWindow || e !== en.currentWindow) && (!!(en.currentWindow && en.currentWindow.location && n && n === en.currentWindow.location.origin) || G(new URL(n)));
    }
    function hn(e, n) {
      v.isFramelessWindow || en.parentWindow && !en.parentWindow.closed && e !== en.parentWindow ? en.childWindow && !en.childWindow.closed && e !== en.childWindow || (en.childWindow = e, en.childOrigin = n) : (en.parentWindow = e, en.parentOrigin = n), en.parentWindow && en.parentWindow.closed && (en.parentWindow = null, en.parentOrigin = null), en.childWindow && en.childWindow.closed && (en.childWindow = null, en.childOrigin = null), An(en.parentWindow), An(en.childWindow);
    }
    var wn = Ye.extend("handleParentMessage");
    function Cn(e) {
      var n = wn;
      if ("id" in e.data && "number" == typeof e.data.id) {
        var t = e.data,
          o = nn.callbacks[t.id];
        n("Received a response from parent for message %i", t.id), o && (n("Invoking the registered callback for message %i with arguments %o", t.id, t.args), o.apply(null, $e($e([], t.args, !0), [t.isPartialResponse], !1)), vn(e) || (n("Removing registered callback for message %i", t.id), delete nn.callbacks[t.id]));
        var i = nn.promiseCallbacks[t.id];
        i && (n("Invoking the registered promise callback for message %i with arguments %o", t.id, t.args), i(t.args), n("Removing registered promise callback for message %i", t.id), delete nn.promiseCallbacks[t.id]);
      } else if ("func" in e.data && "string" == typeof e.data.func) {
        n("Received an action message %s from parent", (t = e.data).func), _e(t.func, t.args);
      } else n("Received an unknown message: %O", e);
    }
    function vn(e) {
      return !0 === e.data.isPartialResponse;
    }
    function yn(e) {
      if ("id" in e.data && "func" in e.data) {
        var n = e.data,
          t = _e(n.func, n.args),
          o = t[0],
          i = t[1];
        o && void 0 !== i ? On(n.id, Array.isArray(i) ? i : [i]) : dn(n.func, n.args, function () {
          for (var e = [], t = 0; t < arguments.length; t++) e[t] = arguments[t];
          if (en.childWindow) {
            var o = e.pop();
            On(n.id, e, o);
          }
        });
      }
    }
    function Sn(e) {
      return e === en.parentWindow ? nn.parentMessageQueue : e === en.childWindow ? nn.childMessageQueue : [];
    }
    function bn(e) {
      return e === en.parentWindow ? en.parentOrigin : e === en.childWindow ? en.childOrigin : null;
    }
    var Tn,
      In,
      En,
      Pn,
      Fn,
      kn = Ye.extend("flushMessageQueue");
    function An(e) {
      for (var n = bn(e), t = Sn(e), o = e == en.parentWindow ? "parent" : "child"; e && n && t.length > 0;) {
        var i = t.shift();
        kn("Flushing message %i from " + o + " message queue via postMessage.", i.id), e.postMessage(i, n);
      }
    }
    function Rn(e, n) {
      var t = en.currentWindow.setInterval(function () {
        0 === Sn(e).length && (clearInterval(t), n());
      }, 100);
    }
    function On(e, n, t) {
      var o = en.childWindow,
        i = Nn(e, n, t),
        r = bn(o);
      o && r && o.postMessage(i, r);
    }
    function Mn(e, n) {
      var t = en.childWindow,
        o = Ln(e, n),
        i = bn(t);
      t && i ? t.postMessage(o, i) : Sn(t).push(o);
    }
    function Dn(e, n) {
      return {
        id: nn.nextMessageId++,
        func: e,
        timestamp: Date.now(),
        args: n || []
      };
    }
    function Nn(e, n, t) {
      return {
        id: e,
        args: n || [],
        isPartialResponse: t
      };
    }
    function Ln(e, n) {
      return {
        func: e,
        args: n || []
      };
    }
    function Un(e, n) {
      Ae(le), dn("uploadCustomApp", [e], n || q());
    }
    function Hn(e, n, t) {
      Ae(le), dn(e, n, t);
    }
    function Vn(e, n) {
      if (Ae(le), !en.childWindow) throw new Error("The child window has not yet been initialized or is not present");
      Mn(e, n);
    }
    function Wn(e, n) {
      var t = this;
      Ae(le), Be(e, function () {
        for (var e = [], o = 0; o < arguments.length; o++) e[o] = arguments[o];
        return n.apply(t, e);
      });
    }
    function xn(e, n) {
      Ae(le), Be("userSettingsChange", n, !0, [e]);
    }
    function _n(e) {
      Ae(le, U.content, U.task), dn("openFilePreview", [e.entityId, e.title, e.description, e.type, e.objectUrl, e.downloadUrl, e.webPreviewUrl, e.webEditUrl, e.baseUrl, e.editFile, e.subEntityId, e.viewerAction, e.fileOpenPreference, e.conversationId]);
    }
    !function (e) {
      function n() {
        return !(!Ae(le) || !le.supports.logs);
      }
      e.registerGetLogHandler = function (e) {
        if (e && Ae(le), e && !n()) throw B;
        e ? Be("log.request", function () {
          dn("log.receive", [e()]);
        }) : ze("log.request");
      }, e.isSupported = n;
    }(Tn || (Tn = {})), function (e) {
      e.fileDownloadStart = "fileDownloadStart", e.fileDownloadComplete = "fileDownloadComplete";
    }(In || (In = {})), function (e) {
      e.view = "view", e.edit = "edit", e.editNew = "editNew";
    }(En || (En = {})), function (e) {
      e.fileOpenPreference = "fileOpenPreference", e.theme = "theme";
    }(Pn || (Pn = {})), function (e) {
      function n() {
        return !(!Ae(le) || !le.supports.conversations);
      }
      e.openConversation = function (e) {
        return new Promise(function (t) {
          if (Ae(le, U.content), !n()) throw B;
          var o = an("conversations.openConversation", {
            title: e.title,
            subEntityId: e.subEntityId,
            conversationId: e.conversationId,
            channelId: e.channelId,
            entityId: e.entityId
          });
          e.onStartConversation && Be("startConversation", function (n, t, o, i) {
            return e.onStartConversation({
              subEntityId: n,
              conversationId: t,
              channelId: o,
              entityId: i
            });
          }), e.onCloseConversation && Be("closeConversation", function (n, t, o, i) {
            return e.onCloseConversation({
              subEntityId: n,
              conversationId: t,
              channelId: o,
              entityId: i
            });
          }), t(o);
        });
      }, e.closeConversation = function () {
        if (Ae(le, U.content), !n()) throw B;
        dn("conversations.closeConversation"), ze("startConversation"), ze("closeConversation");
      }, e.getChatMembers = function () {
        return new Promise(function (e) {
          if (Ae(le), !n()) throw B;
          e(rn("getChatMembers"));
        });
      }, e.isSupported = n;
    }(Fn || (Fn = {}));
    var Bn,
      zn = "/l/app/",
      jn = "/l/meeting/new",
      Gn = "attendees",
      qn = "startTime",
      Kn = "endTime",
      Zn = "subject",
      Jn = "content",
      Qn = "/l/call/0/0",
      Xn = "source",
      $n = "withVideo",
      Yn = "/l/chat/0/0",
      et = "users",
      nt = "topicName",
      tt = "message";
    function ot(e, n, t) {
      if (0 === e.length) throw new Error("Must have at least one user when creating a chat deep link");
      var o = "".concat(et, "=") + e.map(function (e) {
          return encodeURIComponent(e);
        }).join(","),
        i = void 0 === n ? "" : "&".concat(nt, "=").concat(encodeURIComponent(n)),
        r = void 0 === t ? "" : "&".concat(tt, "=").concat(encodeURIComponent(t));
      return "".concat(g, "://").concat(m).concat(Yn, "?").concat(o).concat(i).concat(r);
    }
    function it(e, n, t) {
      if (0 === e.length) throw new Error("Must have at least one target when creating a call deep link");
      var o = "".concat(et, "=") + e.map(function (e) {
          return encodeURIComponent(e);
        }).join(","),
        i = void 0 === n ? "" : "&".concat($n, "=").concat(encodeURIComponent(n)),
        r = void 0 === t ? "" : "&".concat(Xn, "=").concat(encodeURIComponent(t));
      return "".concat(g, "://").concat(m).concat(Qn, "?").concat(o).concat(i).concat(r);
    }
    function rt(e, n, t, o, i) {
      var r = void 0 === e ? "" : "".concat(Gn, "=") + e.map(function (e) {
          return encodeURIComponent(e);
        }).join(","),
        a = void 0 === n ? "" : "&".concat(qn, "=").concat(encodeURIComponent(n)),
        s = void 0 === t ? "" : "&".concat(Kn, "=").concat(encodeURIComponent(t)),
        l = void 0 === o ? "" : "&".concat(Zn, "=").concat(encodeURIComponent(o)),
        c = void 0 === i ? "" : "&".concat(Jn, "=").concat(encodeURIComponent(i));
      return "".concat(g, "://").concat(m).concat(jn, "?").concat(r).concat(a).concat(s).concat(l).concat(c);
    }
    function at(e) {
      if (!e) throw new Error("App ID must be set when creating an app install dialog deep link");
      return "".concat(g, "://").concat(m).concat(zn).concat(encodeURIComponent(e));
    }
    !function (e) {
      function n() {
        return !(!Ae(le) || !le.supports.appInstallDialog);
      }
      e.openAppInstallDialog = function (e) {
        return new Promise(function (t) {
          if (Ae(le, U.content, U.sidePanel, U.settings, U.task, U.stage, U.meetingStage), !n()) throw new Error("Not supported");
          le.isLegacyTeams ? t(an("executeDeepLink", at(e.appId))) : (dn("appInstallDialog.openAppInstallDialog", [e]), t());
        });
      }, e.isSupported = n;
    }(Bn || (Bn = {}));
    var st,
      lt,
      ct,
      ut,
      dt = function () {
        var e = function (n, t) {
          return e = Object.setPrototypeOf || {
            __proto__: []
          } instanceof Array && function (e, n) {
            e.__proto__ = n;
          } || function (e, n) {
            for (var t in n) Object.prototype.hasOwnProperty.call(n, t) && (e[t] = n[t]);
          }, e(n, t);
        };
        return function (n, t) {
          if ("function" != typeof t && null !== t) throw new TypeError("Class extends value " + String(t) + " is not a constructor or null");
          function o() {
            this.constructor = n;
          }
          e(n, t), n.prototype = null === t ? Object.create(t) : (o.prototype = t.prototype, new o());
        };
      }();
    function ft(e, n) {
      if (null == e || null == n || e.length <= 0) return null;
      var t,
        o = 1;
      return e.sort(function (e, n) {
        return e.sequence > n.sequence ? 1 : -1;
      }), e.forEach(function (e) {
        e.sequence == o && (t = t ? new Blob([t, e.file], {
          type: n
        }) : new Blob([e.file], {
          type: n
        }), o++);
      }), t;
    }
    function pt(e, n) {
      if (null == e || null == n) return null;
      for (var t = atob(e.chunk), o = new Array(t.length), i = 0; i < t.length; i++) o[i] = t.charCodeAt(i);
      var r = new Uint8Array(o),
        a = new Blob([r], {
          type: n
        });
      return {
        sequence: e.chunkSequence,
        file: a
      };
    }
    function gt(e) {
      Ct(e) ? Me(n) : vt(e) ? Me(i) : wt(e) && Me(r);
    }
    function mt(e) {
      return !(e.mediaType != st.MediaType.Video || !e.videoProps || !e.videoProps.videoController);
    }
    function ht(e) {
      return !(null == e || e.maxMediaCount > 10);
    }
    function wt(e) {
      var n;
      return !((null == e ? void 0 : e.mediaType) != st.MediaType.Image || !(null === (n = null == e ? void 0 : e.imageProps) || void 0 === n ? void 0 : n.imageOutputFormats));
    }
    function Ct(e) {
      return !(!e || e.mediaType != st.MediaType.VideoAndImage && !e.videoAndImageProps);
    }
    function vt(e) {
      return !(!e || e.mediaType != st.MediaType.Video || !e.videoProps || e.videoProps.isFullScreenMode);
    }
    function yt(e, n, t) {
      return null != e && null != n && n == st.FileFormat.ID && null != t;
    }
    function St(e) {
      return !(null == e || e.length <= 0 || e.length > 10);
    }
    function bt(e) {
      return !e || !(null === e.timeOutIntervalInSec || e.timeOutIntervalInSec <= 0 || e.timeOutIntervalInSec > 60);
    }
    function Tt(e) {
      if (e) {
        if (e.title && "string" != typeof e.title) return !1;
        if (e.setSelected && "object" != typeof e.setSelected) return !1;
        if (e.openOrgWideSearchInChatOrChannel && "boolean" != typeof e.openOrgWideSearchInChatOrChannel) return !1;
        if (e.singleSelect && "boolean" != typeof e.singleSelect) return !1;
      }
      return !0;
    }
    function It() {
      return le.hostVersionsInfo ? le.hostVersionsInfo.adaptiveCardSchemaVersion : void 0;
    }
    !function (e) {
      !function (e) {
        e.Base64 = "base64", e.ID = "id";
      }(e.FileFormat || (e.FileFormat = {}));
      var n = function () {
        return function () {};
      }();
      e.File = n, e.captureImage = function (e) {
        if (!e) throw new Error("[captureImage] Callback cannot be null");
        Ae(le, U.content, U.task), v.isFramelessWindow ? Re(c) ? dn("captureImage", e) : e({
          errorCode: M.OLD_PLATFORM
        }, void 0) : e({
          errorCode: M.NOT_SUPPORTED_ON_PLATFORM
        }, void 0);
      };
      var t = function (e) {
        function n(n) {
          void 0 === n && (n = null);
          var t = e.call(this) || this;
          return n && (t.content = n.content, t.format = n.format, t.mimeType = n.mimeType, t.name = n.name, t.preview = n.preview, t.size = n.size), t;
        }
        return dt(n, e), n.prototype.getMedia = function (e) {
          if (!e) throw new Error("[get Media] Callback cannot be null");
          (Ae(le, U.content, U.task), Re(u)) ? yt(this.mimeType, this.format, this.content) ? Re("2.0.0") ? this.getMediaViaCallback(e) : this.getMediaViaHandler(e) : e({
            errorCode: M.INVALID_ARGUMENTS
          }, null) : e({
            errorCode: M.OLD_PLATFORM
          }, null);
        }, n.prototype.getMediaViaCallback = function (e) {
          var n = {
            mediaMimeType: this.mimeType,
            assembleAttachment: []
          };
          dn("getMedia", [this.content], function (t) {
            if (e) if (t && t.error) e(t.error, null);else if (t && t.mediaChunk) {
              if (t.mediaChunk.chunkSequence <= 0) {
                var o = ft(n.assembleAttachment, n.mediaMimeType);
                e(t.error, o);
              } else {
                var i = pt(t.mediaChunk, n.mediaMimeType);
                n.assembleAttachment.push(i);
              }
            } else e({
              errorCode: M.INTERNAL_ERROR,
              message: "data received is null"
            }, null);
          });
        }, n.prototype.getMediaViaHandler = function (e) {
          var n = Z(),
            t = {
              mediaMimeType: this.mimeType,
              assembleAttachment: []
            },
            o = [n, this.content];
          this.content && e && dn("getMedia", o), Be("getMedia" + n, function (o) {
            if (e) {
              var i = JSON.parse(o);
              if (i.error) e(i.error, null), ze("getMedia" + n);else if (i.mediaChunk) {
                if (i.mediaChunk.chunkSequence <= 0) {
                  var r = ft(t.assembleAttachment, t.mediaMimeType);
                  e(i.error, r), ze("getMedia" + n);
                } else {
                  var a = pt(i.mediaChunk, t.mediaMimeType);
                  t.assembleAttachment.push(a);
                }
              } else e({
                errorCode: M.INTERNAL_ERROR,
                message: "data received is null"
              }, null), ze("getMedia" + n);
            }
          });
        }, n;
      }(n);
      e.Media = t;
      var o,
        r,
        a = function (e) {
          function n() {
            return null !== e && e.apply(this, arguments) || this;
          }
          return dt(n, e), n.prototype.getMediaType = function () {
            return r.Video;
          }, n.prototype.notifyEventToApp = function (e) {
            if (this.controllerCallback) switch (e) {
              case o.StartRecording:
                if (this.controllerCallback.onRecordingStarted) {
                  this.controllerCallback.onRecordingStarted();
                  break;
                }
            }
          }, n;
        }(function () {
          function e(e) {
            this.controllerCallback = e;
          }
          return e.prototype.notifyEventToHost = function (e, n) {
            Ae(le, U.content, U.task);
            try {
              Me(i);
            } catch (e) {
              return void (n && n(e));
            }
            dn("media.controller", [{
              mediaType: this.getMediaType(),
              mediaControllerEvent: e
            }], function (e) {
              n && n(e);
            });
          }, e.prototype.stop = function (e) {
            this.notifyEventToHost(o.StopRecording, e);
          }, e;
        }());
      e.VideoController = a, function (e) {
        e[e.StartRecording = 1] = "StartRecording", e[e.StopRecording = 2] = "StopRecording";
      }(o = e.MediaControllerEvent || (e.MediaControllerEvent = {})), function (e) {
        e[e.Photo = 1] = "Photo", e[e.Document = 2] = "Document", e[e.Whiteboard = 3] = "Whiteboard", e[e.BusinessCard = 4] = "BusinessCard";
      }(e.CameraStartMode || (e.CameraStartMode = {})), function (e) {
        e[e.Camera = 1] = "Camera", e[e.Gallery = 2] = "Gallery";
      }(e.Source || (e.Source = {})), function (e) {
        e[e.Image = 1] = "Image", e[e.Video = 2] = "Video", e[e.VideoAndImage = 3] = "VideoAndImage", e[e.Audio = 4] = "Audio";
      }(r = e.MediaType || (e.MediaType = {})), function (e) {
        e[e.ID = 1] = "ID", e[e.URL = 2] = "URL";
      }(e.ImageUriType || (e.ImageUriType = {})), function (e) {
        e[e.IMAGE = 1] = "IMAGE", e[e.PDF = 2] = "PDF";
      }(e.ImageOutputFormats || (e.ImageOutputFormats = {})), e.selectMedia = function (e, n) {
        if (!n) throw new Error("[select Media] Callback cannot be null");
        if (Ae(le, U.content, U.task), Re(u)) {
          try {
            gt(e);
          } catch (e) {
            return void n(e, null);
          }
          if (ht(e)) {
            dn("selectMedia", [e], function (o, i, r) {
              if (r) mt(e) && e.videoProps.videoController.notifyEventToApp(r);else if (i) {
                for (var a = [], s = 0, l = i; s < l.length; s++) {
                  var c = l[s];
                  a.push(new t(c));
                }
                n(o, a);
              } else n(o, null);
            });
          } else {
            var o = {
              errorCode: M.INVALID_ARGUMENTS
            };
            n(o, null);
          }
        } else {
          var i = {
            errorCode: M.OLD_PLATFORM
          };
          n(i, null);
        }
      }, e.viewImages = function (e, n) {
        if (!n) throw new Error("[view images] Callback cannot be null");
        Ae(le, U.content, U.task), Re(u) ? St(e) ? dn("viewImages", [e], n) : n({
          errorCode: M.INVALID_ARGUMENTS
        }) : n({
          errorCode: M.OLD_PLATFORM
        });
      }, e.scanBarCode = function (e, n) {
        if (!e) throw new Error("[media.scanBarCode] Callback cannot be null");
        Ae(le, U.content, U.task), v.hostClientType !== N.desktop && v.hostClientType !== N.web && v.hostClientType !== N.rigel && v.hostClientType !== N.teamsRoomsWindows && v.hostClientType !== N.teamsRoomsAndroid && v.hostClientType !== N.teamsPhones && v.hostClientType !== N.teamsDisplays ? Re(d) ? bt(n) ? dn("media.scanBarCode", [n], e) : e({
          errorCode: M.INVALID_ARGUMENTS
        }, null) : e({
          errorCode: M.OLD_PLATFORM
        }, null) : e({
          errorCode: M.NOT_SUPPORTED_ON_PLATFORM
        }, null);
      };
    }(st || (st = {})), function (e) {
      function n() {
        return !!(Ae(le) && le.supports.barCode && le.supports.permissions);
      }
      e.scanBarCode = function (e) {
        return new Promise(function (t) {
          if (Ae(le, U.content, U.task), !n()) throw B;
          if (!bt(e)) throw {
            errorCode: M.INVALID_ARGUMENTS
          };
          t(ln("media.scanBarCode", e));
        });
      }, e.hasPermission = function () {
        if (Ae(le, U.content, U.task), !n()) throw B;
        var e = D.Media;
        return new Promise(function (n) {
          n(ln("permissions.has", e));
        });
      }, e.requestPermission = function () {
        if (Ae(le, U.content, U.task), !n()) throw B;
        var e = D.Media;
        return new Promise(function (n) {
          n(ln("permissions.request", e));
        });
      }, e.isSupported = n;
    }(lt || (lt = {})), function (e) {
      function n(e) {
        return new Promise(function (n) {
          if (Ae(le, U.content, U.task), !t()) throw B;
          le.isLegacyTeams ? n(an("executeDeepLink", ot([e.user], void 0, e.message))) : n(an("chat.openChat", {
            members: e.user,
            message: e.message
          }));
        });
      }
      function t() {
        return !(!Ae(le) || !le.supports.chat);
      }
      e.openChat = n, e.openGroupChat = function (e) {
        return new Promise(function (o) {
          if (e.users.length < 1) throw Error("OpenGroupChat Failed: No users specified");
          if (1 === e.users.length) {
            n({
              user: e.users[0],
              message: e.message
            });
          } else {
            if (Ae(le, U.content, U.task), !t()) throw B;
            if (le.isLegacyTeams) o(an("executeDeepLink", ot(e.users, e.topic, e.message)));else o(an("chat.openChat", {
              members: e.users,
              message: e.message,
              topic: e.topic
            }));
          }
        });
      }, e.isSupported = t;
    }(ct || (ct = {})), function (e) {
      function n() {
        return !!(Ae(le) && le.supports.geoLocation && le.supports.permissions);
      }
      e.getCurrentLocation = function () {
        if (Ae(le, U.content, U.task), !n()) throw B;
        return ln("location.getLocation", {
          allowChooseLocation: !1,
          showMap: !1
        });
      }, e.hasPermission = function () {
        if (Ae(le, U.content, U.task), !n()) throw B;
        var e = D.GeoLocation;
        return new Promise(function (n) {
          n(ln("permissions.has", e));
        });
      }, e.requestPermission = function () {
        if (Ae(le, U.content, U.task), !n()) throw B;
        var e = D.GeoLocation;
        return new Promise(function (n) {
          n(ln("permissions.request", e));
        });
      }, e.isSupported = n, function (e) {
        function n() {
          return !!(Ae(le) && le.supports.geoLocation && le.supports.geoLocation.map && le.supports.permissions);
        }
        e.chooseLocation = function () {
          if (Ae(le, U.content, U.task), !n()) throw B;
          return ln("location.getLocation", {
            allowChooseLocation: !0,
            showMap: !0
          });
        }, e.showLocation = function (e) {
          if (Ae(le, U.content, U.task), !n()) throw B;
          if (!e) throw {
            errorCode: M.INVALID_ARGUMENTS
          };
          return ln("location.showLocation", e);
        }, e.isSupported = n;
      }(e.map || (e.map = {}));
    }(ut || (ut = {}));
    var Et,
      Pt = function () {
        function e() {}
        return e.prototype.postMessage = function (e, n) {
          Ae(le), dn("messageForChild", [e], n || q());
        }, e.prototype.addEventListener = function (e, n) {
          Ae(le), "message" === e && Be("messageForParent", n);
        }, e;
      }(),
      Ft = function () {
        function e() {}
        return Object.defineProperty(e, "Instance", {
          get: function () {
            return this._instance || (this._instance = new this());
          },
          enumerable: !1,
          configurable: !0
        }), e.prototype.postMessage = function (e, n) {
          Ae(le, U.task), dn("messageForParent", [e], n || q());
        }, e.prototype.addEventListener = function (e, n) {
          Ae(le, U.task), "message" === e && Be("messageForChild", n);
        }, e;
      }();
    !function (e) {
      function n() {
        return !(!Ae(le) || !le.supports.location);
      }
      e.getLocation = function (e, t) {
        if (!t) throw new Error("[location.getLocation] Callback cannot be null");
        if (Ae(le, U.content, U.task), !Re(s)) throw {
          errorCode: M.OLD_PLATFORM
        };
        if (!e) throw {
          errorCode: M.INVALID_ARGUMENTS
        };
        if (!n()) throw B;
        dn("location.getLocation", [e], t);
      }, e.showLocation = function (e, t) {
        if (!t) throw new Error("[location.showLocation] Callback cannot be null");
        if (Ae(le, U.content, U.task), !Re(s)) throw {
          errorCode: M.OLD_PLATFORM
        };
        if (!e) throw {
          errorCode: M.INVALID_ARGUMENTS
        };
        if (!n()) throw B;
        dn("location.showLocation", [e], t);
      }, e.isSupported = n;
    }(Et || (Et = {}));
    var kt,
      At,
      Rt,
      Ot,
      Mt,
      Dt,
      Nt = function (e, n, t, o) {
        function i(e) {
          return e instanceof t ? e : new t(function (n) {
            n(e);
          });
        }
        return new (t || (t = Promise))(function (t, r) {
          function a(e) {
            try {
              l(o.next(e));
            } catch (e) {
              r(e);
            }
          }
          function s(e) {
            try {
              l(o.throw(e));
            } catch (e) {
              r(e);
            }
          }
          function l(e) {
            e.done ? t(e.value) : i(e.value).then(a, s);
          }
          l((o = o.apply(e, n || [])).next());
        });
      },
      Lt = function (e, n) {
        var t,
          o,
          i,
          r,
          a = {
            label: 0,
            sent: function () {
              if (1 & i[0]) throw i[1];
              return i[1];
            },
            trys: [],
            ops: []
          };
        return r = {
          next: s(0),
          throw: s(1),
          return: s(2)
        }, "function" == typeof Symbol && (r[Symbol.iterator] = function () {
          return this;
        }), r;
        function s(e) {
          return function (n) {
            return l([e, n]);
          };
        }
        function l(s) {
          if (t) throw new TypeError("Generator is already executing.");
          for (; r && (r = 0, s[0] && (a = 0)), a;) try {
            if (t = 1, o && (i = 2 & s[0] ? o.return : s[0] ? o.throw || ((i = o.return) && i.call(o), 0) : o.next) && !(i = i.call(o, s[1])).done) return i;
            switch (o = 0, i && (s = [2 & s[0], i.value]), s[0]) {
              case 0:
              case 1:
                i = s;
                break;
              case 4:
                return a.label++, {
                  value: s[1],
                  done: !1
                };
              case 5:
                a.label++, o = s[1], s = [0];
                continue;
              case 7:
                s = a.ops.pop(), a.trys.pop();
                continue;
              default:
                if (!(i = a.trys, (i = i.length > 0 && i[i.length - 1]) || 6 !== s[0] && 2 !== s[0])) {
                  a = 0;
                  continue;
                }
                if (3 === s[0] && (!i || s[1] > i[0] && s[1] < i[3])) {
                  a.label = s[1];
                  break;
                }
                if (6 === s[0] && a.label < i[1]) {
                  a.label = i[1], i = s;
                  break;
                }
                if (i && a.label < i[2]) {
                  a.label = i[2], a.ops.push(s);
                  break;
                }
                i[2] && a.ops.pop(), a.trys.pop();
                continue;
            }
            s = n.call(e, a);
          } catch (e) {
            s = [6, e], o = 0;
          } finally {
            t = i = 0;
          }
          if (5 & s[0]) throw s[1];
          return {
            value: s[0] ? s[1] : void 0,
            done: !0
          };
        }
      };
    function Ut(e) {
      return e ? e.modality && "string" != typeof e.modality ? [!1, "modality must be a string"] : e.targetElementBoundingRect && "object" == typeof e.targetElementBoundingRect ? e.triggerType && "string" == typeof e.triggerType ? Ht(e.persona) : [!1, "triggerType must be a valid string"] : [!1, "targetElementBoundingRect must be a DOMRect"] : [!1, "A request object is required"];
    }
    function Ht(e) {
      return e ? e.displayName && "string" != typeof e.displayName ? [!1, "displayName must be a string"] : e.identifiers && "object" == typeof e.identifiers ? e.identifiers.AadObjectId || e.identifiers.Smtp || e.identifiers.Upn ? e.identifiers.AadObjectId && "string" != typeof e.identifiers.AadObjectId ? [!1, "AadObjectId identifier must be a string"] : e.identifiers.Smtp && "string" != typeof e.identifiers.Smtp ? [!1, "Smtp identifier must be a string"] : e.identifiers.Upn && "string" != typeof e.identifiers.Upn ? [!1, "Upn identifier must be a string"] : [!0, void 0] : [!1, "at least one valid identifier must be provided"] : [!1, "persona identifiers object must be provided"] : [!1, "persona object must be provided"];
    }
    !function (e) {
      var n;
      function t(e, t) {
        var o = this;
        dn("meeting.requestAppAudioHandling", [e.isAppHandlingAudio], function (r, a) {
          if (r && null != a) throw new Error("[requestAppAudioHandling] Callback response - both parameters cannot be set");
          if (r) throw new Error("[requestAppAudioHandling] Callback response - SDK error ".concat(r.errorCode, " ").concat(r.message));
          if ("boolean" != typeof a) throw new Error("[requestAppAudioHandling] Callback response - isHostAudioless must be a boolean");
          Be("meeting.micStateChanged", function (t) {
            return Nt(o, void 0, void 0, function () {
              var o, r;
              return Lt(this, function (a) {
                switch (a.label) {
                  case 0:
                    return a.trys.push([0, 2,, 3]), [4, e.micMuteStateChangedCallback(t)];
                  case 1:
                    return o = a.sent(), r = o.isMicMuted === t.isMicMuted, i(o, r ? n.HostInitiated : n.AppDeclinedToChange), [3, 3];
                  case 2:
                    return a.sent(), i(t, n.AppFailedToChange), [3, 3];
                  case 3:
                    return [2];
                }
              });
            });
          }), t(a);
        });
      }
      function o(e, n) {
        dn("meeting.requestAppAudioHandling", [e.isAppHandlingAudio], function (e, t) {
          if (e && null != t) throw new Error("[requestAppAudioHandling] Callback response - both parameters cannot be set");
          if (e) throw new Error("[requestAppAudioHandling] Callback response - SDK error ".concat(e.errorCode, " ").concat(e.message));
          if ("boolean" != typeof t) throw new Error("[requestAppAudioHandling] Callback response - isHostAudioless must be a boolean");
          je("meeting.micStateChanged") && ze("meeting.micStateChanged"), n(t);
        });
      }
      function i(e, n) {
        Ae(le, U.sidePanel, U.meetingStage), dn("meeting.updateMicState", [e, n]);
      }
      !function (e) {
        e[e.HostInitiated = 0] = "HostInitiated", e[e.AppInitiated = 1] = "AppInitiated", e[e.AppDeclinedToChange = 2] = "AppDeclinedToChange", e[e.AppFailedToChange = 3] = "AppFailedToChange";
      }(n || (n = {})), function (e) {
        e.like = "like", e.heart = "heart", e.laugh = "laugh", e.surprised = "surprised", e.applause = "applause";
      }(e.MeetingReactionType || (e.MeetingReactionType = {})), function (e) {
        e.Unknown = "Unknown", e.Adhoc = "Adhoc", e.Scheduled = "Scheduled", e.Recurring = "Recurring", e.Broadcast = "Broadcast", e.MeetNow = "MeetNow";
      }(e.MeetingType || (e.MeetingType = {})), function (e) {
        e.OneOnOneCall = "oneOnOneCall", e.GroupCall = "groupCall";
      }(e.CallType || (e.CallType = {})), e.getIncomingClientAudioState = function (e) {
        if (!e) throw new Error("[get incoming client audio state] Callback cannot be null");
        Ae(le, U.sidePanel, U.meetingStage), dn("getIncomingClientAudioState", e);
      }, e.toggleIncomingClientAudio = function (e) {
        if (!e) throw new Error("[toggle incoming client audio] Callback cannot be null");
        Ae(le, U.sidePanel, U.meetingStage), dn("toggleIncomingClientAudio", e);
      }, e.getMeetingDetails = function (e) {
        if (!e) throw new Error("[get meeting details] Callback cannot be null");
        Ae(le, U.sidePanel, U.meetingStage, U.settings, U.content), dn("meeting.getMeetingDetails", e);
      }, e.getAuthenticationTokenForAnonymousUser = function (e) {
        if (!e) throw new Error("[get Authentication Token For AnonymousUser] Callback cannot be null");
        Ae(le, U.sidePanel, U.meetingStage, U.task), dn("meeting.getAuthenticationTokenForAnonymousUser", e);
      }, e.getLiveStreamState = function (e) {
        if (!e) throw new Error("[get live stream state] Callback cannot be null");
        Ae(le, U.sidePanel), dn("meeting.getLiveStreamState", e);
      }, e.requestStartLiveStreaming = function (e, n, t) {
        if (!e) throw new Error("[request start live streaming] Callback cannot be null");
        Ae(le, U.sidePanel), dn("meeting.requestStartLiveStreaming", [n, t], e);
      }, e.requestStopLiveStreaming = function (e) {
        if (!e) throw new Error("[request stop live streaming] Callback cannot be null");
        Ae(le, U.sidePanel), dn("meeting.requestStopLiveStreaming", e);
      }, e.registerLiveStreamChangedHandler = function (e) {
        if (!e) throw new Error("[register live stream changed handler] Handler cannot be null");
        Ae(le, U.sidePanel), Be("meeting.liveStreamChanged", e);
      }, e.shareAppContentToStage = function (e, n) {
        if (!e) throw new Error("[share app content to stage] Callback cannot be null");
        Ae(le, U.sidePanel, U.meetingStage), dn("meeting.shareAppContentToStage", [n], e);
      }, e.getAppContentStageSharingCapabilities = function (e) {
        if (!e) throw new Error("[get app content stage sharing capabilities] Callback cannot be null");
        Ae(le, U.sidePanel, U.meetingStage), dn("meeting.getAppContentStageSharingCapabilities", e);
      }, e.stopSharingAppContentToStage = function (e) {
        if (!e) throw new Error("[stop sharing app content to stage] Callback cannot be null");
        Ae(le, U.sidePanel, U.meetingStage), dn("meeting.stopSharingAppContentToStage", e);
      }, e.getAppContentStageSharingState = function (e) {
        if (!e) throw new Error("[get app content stage sharing state] Callback cannot be null");
        Ae(le, U.sidePanel, U.meetingStage), dn("meeting.getAppContentStageSharingState", e);
      }, e.registerSpeakingStateChangeHandler = function (e) {
        if (!e) throw new Error("[registerSpeakingStateChangeHandler] Handler cannot be null");
        Ae(le, U.sidePanel, U.meetingStage), Be("meeting.speakingStateChanged", e);
      }, e.registerRaiseHandStateChangedHandler = function (e) {
        if (!e) throw new Error("[registerRaiseHandStateChangedHandler] Handler cannot be null");
        Ae(le, U.sidePanel, U.meetingStage), Be("meeting.raiseHandStateChanged", e);
      }, e.registerMeetingReactionReceivedHandler = function (e) {
        if (!e) throw new Error("[registerMeetingReactionReceivedHandler] Handler cannot be null");
        Ae(le, U.sidePanel, U.meetingStage), Be("meeting.meetingReactionReceived", e);
      }, function (e) {
        e.setOptions = function (e) {
          Ae(le, U.sidePanel), e.contentUrl && new URL(e.contentUrl), dn("meeting.appShareButton.setOptions", [e]);
        };
      }(e.appShareButton || (e.appShareButton = {})), e.requestAppAudioHandling = function (e, n) {
        if (!n) throw new Error("[requestAppAudioHandling] Callback response cannot be null");
        if (!e.micMuteStateChangedCallback) throw new Error("[requestAppAudioHandling] Callback Mic mute state handler cannot be null");
        Ae(le, U.sidePanel, U.meetingStage), e.isAppHandlingAudio ? t(e, n) : o(e, n);
      }, e.updateMicState = function (e) {
        i(e, n.AppInitiated);
      };
    }(kt || (kt = {})), function (e) {
      function n() {
        return !(!Ae(le) || !le.supports.monetization);
      }
      e.openPurchaseExperience = function (e, t) {
        var o, i;
        return "function" == typeof e ? (o = e, i = t) : i = e, Ae(le, U.content), $(function () {
          return new Promise(function (e) {
            if (!n()) throw B;
            e(ln("monetization.openPurchaseExperience", i));
          });
        }, o);
      }, e.isSupported = n;
    }(At || (At = {})), function (e) {
      function n() {
        return !(!Ae(le) || !le.supports.calendar);
      }
      e.openCalendarItem = function (e) {
        return new Promise(function (t) {
          if (Ae(le, U.content), !n()) throw new Error("Not supported");
          if (!e.itemId || !e.itemId.trim()) throw new Error("Must supply an itemId to openCalendarItem");
          t(an("calendar.openCalendarItem", e));
        });
      }, e.composeMeeting = function (e) {
        return new Promise(function (t) {
          if (Ae(le, U.content), !n()) throw new Error("Not supported");
          le.isLegacyTeams ? t(an("executeDeepLink", rt(e.attendees, e.startTime, e.endTime, e.subject, e.content))) : t(an("calendar.composeMeeting", e));
        });
      }, e.isSupported = n;
    }(Rt || (Rt = {})), function (e) {
      function n() {
        return !(!Ae(le) || !le.supports.mail);
      }
      e.openMailItem = function (e) {
        return new Promise(function (t) {
          if (Ae(le, U.content), !n()) throw new Error("Not supported");
          if (!e.itemId || !e.itemId.trim()) throw new Error("Must supply an itemId to openMailItem");
          t(an("mail.openMailItem", e));
        });
      }, e.composeMail = function (e) {
        return new Promise(function (t) {
          if (Ae(le, U.content), !n()) throw new Error("Not supported");
          t(an("mail.composeMail", e));
        });
      }, e.isSupported = n, function (e) {
        e.New = "new", e.Reply = "reply", e.ReplyAll = "replyAll", e.Forward = "forward";
      }(e.ComposeMailType || (e.ComposeMailType = {}));
    }(Ot || (Ot = {})), function (e) {
      function n(e) {
        return new Promise(function (n) {
          if (!Re(l)) throw {
            errorCode: M.OLD_PLATFORM
          };
          if (!Tt(e)) throw {
            errorCode: M.INVALID_ARGUMENTS
          };
          if (!t()) throw B;
          n(ln("people.selectPeople", e));
        });
      }
      function t() {
        return !(!Ae(le) || !le.supports.people);
      }
      e.selectPeople = function (e, t) {
        var o, i, r;
        return Ae(le, U.content, U.task, U.settings), "function" == typeof e ? (i = (o = [e, t])[0], r = o[1]) : r = e, Q(n, i, r);
      }, e.isSupported = t;
    }(Mt || (Mt = {})), function (e) {
      e.showProfile = function (e) {
        return Ae(le, U.content), new Promise(function (n) {
          var t = Ut(e),
            o = t[0],
            i = t[1];
          if (!o) throw {
            errorCode: M.INVALID_ARGUMENTS,
            message: i
          };
          n(ln("profile.showProfile", {
            modality: e.modality,
            persona: e.persona,
            triggerType: e.triggerType,
            targetRectangle: {
              x: e.targetElementBoundingRect.x,
              y: e.targetElementBoundingRect.y,
              width: e.targetElementBoundingRect.width,
              height: e.targetElementBoundingRect.height
            }
          }));
        });
      }, e.isSupported = function () {
        return !(!Ae(le) || !le.supports.profile);
      };
    }(Dt || (Dt = {}));
    var Vt,
      Wt,
      xt,
      _t,
      Bt,
      zt,
      jt,
      Gt,
      qt = function () {
        return qt = Object.assign || function (e) {
          for (var n, t = 1, o = arguments.length; t < o; t++) for (var i in n = arguments[t]) Object.prototype.hasOwnProperty.call(n, i) && (e[i] = n[i]);
          return e;
        }, qt.apply(this, arguments);
      },
      Kt = function (e, n, t, o) {
        function i(e) {
          return e instanceof t ? e : new t(function (n) {
            n(e);
          });
        }
        return new (t || (t = Promise))(function (t, r) {
          function a(e) {
            try {
              l(o.next(e));
            } catch (e) {
              r(e);
            }
          }
          function s(e) {
            try {
              l(o.throw(e));
            } catch (e) {
              r(e);
            }
          }
          function l(e) {
            e.done ? t(e.value) : i(e.value).then(a, s);
          }
          l((o = o.apply(e, n || [])).next());
        });
      },
      Zt = function (e, n) {
        var t,
          o,
          i,
          r,
          a = {
            label: 0,
            sent: function () {
              if (1 & i[0]) throw i[1];
              return i[1];
            },
            trys: [],
            ops: []
          };
        return r = {
          next: s(0),
          throw: s(1),
          return: s(2)
        }, "function" == typeof Symbol && (r[Symbol.iterator] = function () {
          return this;
        }), r;
        function s(e) {
          return function (n) {
            return l([e, n]);
          };
        }
        function l(s) {
          if (t) throw new TypeError("Generator is already executing.");
          for (; r && (r = 0, s[0] && (a = 0)), a;) try {
            if (t = 1, o && (i = 2 & s[0] ? o.return : s[0] ? o.throw || ((i = o.return) && i.call(o), 0) : o.next) && !(i = i.call(o, s[1])).done) return i;
            switch (o = 0, i && (s = [2 & s[0], i.value]), s[0]) {
              case 0:
              case 1:
                i = s;
                break;
              case 4:
                return a.label++, {
                  value: s[1],
                  done: !1
                };
              case 5:
                a.label++, o = s[1], s = [0];
                continue;
              case 7:
                s = a.ops.pop(), a.trys.pop();
                continue;
              default:
                if (!(i = a.trys, (i = i.length > 0 && i[i.length - 1]) || 6 !== s[0] && 2 !== s[0])) {
                  a = 0;
                  continue;
                }
                if (3 === s[0] && (!i || s[1] > i[0] && s[1] < i[3])) {
                  a.label = s[1];
                  break;
                }
                if (6 === s[0] && a.label < i[1]) {
                  a.label = i[1], i = s;
                  break;
                }
                if (i && a.label < i[2]) {
                  a.label = i[2], a.ops.push(s);
                  break;
                }
                i[2] && a.ops.pop(), a.trys.pop();
                continue;
            }
            s = n.call(e, a);
          } catch (e) {
            s = [6, e], o = 0;
          } finally {
            t = i = 0;
          }
          if (5 & s[0]) throw s[1];
          return {
            value: s[0] ? s[1] : void 0,
            done: !0
          };
        }
      };
    function Jt(e, n) {
      be.initialize(n).then(function () {
        e && e();
      });
    }
    function Qt() {
      Se.enablePrintCapability();
    }
    function Xt() {
      Se.print();
    }
    function $t(e) {
      ke(), dn("getContext", function (n) {
        n.frameContext || (n.frameContext = v.frameContext), e(n);
      });
    }
    function Yt(e) {
      be.registerOnThemeChangeHandler(e);
    }
    function eo(e) {
      Ge("fullScreenChange", e, []);
    }
    function no(e) {
      Ge("appButtonClick", e, [U.content]);
    }
    function to(e) {
      Ge("appButtonHoverEnter", e, [U.content]);
    }
    function oo(e) {
      Ge("appButtonHoverLeave", e, [U.content]);
    }
    function io(e) {
      Te.backStack.registerBackButtonHandlerHelper(e);
    }
    function ro(e) {
      Se.registerOnLoadHandlerHelper(e);
    }
    function ao(e) {
      Se.registerBeforeUnloadHandlerHelper(e);
    }
    function so(e) {
      Ge("focusEnter", e, []);
    }
    function lo(e) {
      Ge("changeSettings", e, [U.content]);
    }
    function co(e, n) {
      Ae(le), Te.tabs.getTabInstances(n).then(function (n) {
        e(n);
      });
    }
    function uo(e, n) {
      Ae(le), Te.tabs.getMruTabInstances(n).then(function (n) {
        e(n);
      });
    }
    function fo(e) {
      Te.shareDeepLink({
        subPageId: e.subEntityId,
        subPageLabel: e.subEntityLabel,
        subPageWebUrl: e.subEntityWebUrl
      });
    }
    function po(e, n) {
      Ae(le, U.content, U.sidePanel, U.settings, U.task, U.stage, U.meetingStage), n = n || q(), be.openLink(e).then(function () {
        n(!0);
      }).catch(function (e) {
        n(!1, e.message);
      });
    }
    function go(e) {
      Te.setCurrentFrame(e);
    }
    function mo(e, n, t) {
      Te.initializeWithFrameContext(e, n, t);
    }
    function ho(e) {
      Te.returnFocus(e);
    }
    function wo(e, n) {
      Ae(le), n = n || q(), Te.tabs.navigateToTab(e).then(function () {
        n(!0);
      }).catch(function (e) {
        n(!1, e.message);
      });
    }
    function Co(e, n) {
      Ae(le, U.content, U.sidePanel, U.settings, U.remove, U.task, U.stage, U.meetingStage), n = n || q(), Te.navigateCrossDomain(e).then(function () {
        n(!0);
      }).catch(function (e) {
        n(!1, e.message);
      });
    }
    function vo(e) {
      Ae(le), e = e || q(), Te.backStack.navigateBack().then(function () {
        e(!0);
      }).catch(function (n) {
        e(!1, n.message);
      });
    }
    !function (e) {
      var n, t;
      function o(e) {
        dn("video.notifyError", [e]);
      }
      function i() {
        return !(!Ae(le) || !le.supports.video);
      }
      !function (e) {
        e.NV12 = "NV12";
      }(n = e.VideoFrameFormat || (e.VideoFrameFormat = {})), function (e) {
        e.EffectChanged = "EffectChanged", e.EffectDisabled = "EffectDisabled";
      }(e.EffectChangeType || (e.EffectChangeType = {})), function (e) {
        e.InvalidEffectId = "InvalidEffectId", e.InitializationFailure = "InitializationFailure";
      }(t = e.EffectFailureReason || (e.EffectFailureReason = {})), e.notifySelectedVideoEffectChanged = function (e, n) {
        if (Ae(le, U.sidePanel), !i()) throw B;
        dn("video.videoEffectChanged", [e, n]);
      }, e.registerForVideoEffect = function (e) {
        if (Ae(le, U.sidePanel), !i()) throw B;
        Be("video.effectParameterChange", function (n) {
          e(n).then(function () {
            dn("video.videoEffectReadiness", [!0, n]);
          }).catch(function (e) {
            var o = e in t ? e : t.InitializationFailure;
            dn("video.videoEffectReadiness", [!1, n, o]);
          });
        }, !1), dn("video.registerForVideoEffect");
      }, e.isSupported = i, function (e) {
        function t() {
          var e;
          return Ae(le) && i() && !!(null === (e = le.supports.video) || void 0 === e ? void 0 : e.mediaStream);
        }
        function i() {
          var e, n, t, o;
          return "undefined" != typeof window && !(!(null === (n = null === (e = window.chrome) || void 0 === e ? void 0 : e.webview) || void 0 === n ? void 0 : n.getTextureStream) || !(null === (o = null === (t = window.chrome) || void 0 === t ? void 0 : t.webview) || void 0 === o ? void 0 : o.registerTextureStream));
        }
        function r(e) {
          return Kt(this, void 0, void 0, function () {
            var n, t, i, r, a;
            return Zt(this, function (s) {
              switch (s.label) {
                case 0:
                  if ("undefined" == typeof window) throw B;
                  n = window.chrome, s.label = 1;
                case 1:
                  return s.trys.push([1, 3,, 4]), [4, n.webview.getTextureStream(e)];
                case 2:
                  if (t = s.sent(), 0 === (i = t.getVideoTracks()).length) throw new Error("No video track in stream ".concat(e));
                  return [2, i[0]];
                case 3:
                  throw r = s.sent(), o(a = "Failed to get video track from stream ".concat(e, ", error: ").concat(r)), new Error(a);
                case 4:
                  return [2];
              }
            });
          });
        }
        function a(e, n) {
          var t = new MediaStreamTrackProcessor({
              track: e
            }).readable,
            i = new MediaStreamTrackGenerator({
              kind: "video"
            }),
            r = i.writable;
          return t.pipeThrough(new TransformStream({
            transform: function (e, t) {
              return Kt(this, void 0, void 0, function () {
                var i, r, a, s;
                return Zt(this, function (l) {
                  switch (l.label) {
                    case 0:
                      if (null === (i = e.timestamp)) return [3, 5];
                      l.label = 1;
                    case 1:
                      return l.trys.push([1, 3,, 4]), [4, n({
                        videoFrame: e
                      })];
                    case 2:
                      return r = l.sent(), a = new VideoFrame(r, {
                        timestamp: i
                      }), t.enqueue(a), e.close(), r.close(), [3, 4];
                    case 3:
                      return s = l.sent(), e.close(), o(s), [3, 4];
                    case 4:
                      return [3, 6];
                    case 5:
                      o("timestamp of the original video frame is null"), l.label = 6;
                    case 6:
                      return [2];
                  }
                });
              });
            }
          })).pipeTo(r), i;
        }
        e.isSupported = t, e.registerForVideoFrame = function (e) {
          var o = this;
          if (Ae(le, U.sidePanel), !t()) throw B;
          Be("video.startVideoExtensibilityVideoStream", function (n) {
            return Kt(o, void 0, void 0, function () {
              var t, o, i, s, l;
              return Zt(this, function (c) {
                switch (c.label) {
                  case 0:
                    return [4, r(t = n.streamId)];
                  case 1:
                    return o = c.sent(), i = a(o, e), "undefined" != typeof window && (null === (l = null === (s = window.chrome) || void 0 === s ? void 0 : s.webview) || void 0 === l || l.registerTextureStream(t, i)), [2];
                }
              });
            });
          }), dn("video.mediaStream.registerForVideoFrame", [{
            format: n.NV12
          }]);
        };
      }(e.mediaStream || (e.mediaStream = {})), function (e) {
        function n() {
          var e;
          return !(!Ae(le) || !(null === (e = le.supports.video) || void 0 === e ? void 0 : e.sharedFrame));
        }
        function t(e) {
          dn("video.videoFrameProcessed", [e]);
        }
        e.isSupported = n, e.registerForVideoFrame = function (e, i) {
          if (Ae(le, U.sidePanel), !n()) throw B;
          Be("video.newVideoFrame", function (n) {
            if (n) {
              var i = qt(qt({}, n), {
                  videoFrameBuffer: n.videoFrameBuffer || n.data
                }),
                r = i.timestamp;
              e(i, function () {
                t(r);
              }, o);
            }
          }, !1), dn("video.registerForVideoFrame", [i]);
        };
      }(e.sharedFrame || (e.sharedFrame = {}));
    }(Vt || (Vt = {})), function (e) {
      var n = "search.queryChange",
        t = "search.queryClose",
        o = "search.queryExecute";
      function i() {
        return !(!Ae(le) || !le.supports.search);
      }
      e.registerHandlers = function (e, r, a) {
        if (Ae(le, U.content), !i()) throw B;
        Be(t, e), Be(o, r), a && Be(n, a);
      }, e.unregisterHandlers = function () {
        if (Ae(le, U.content), !i()) throw B;
        dn("search.unregister"), ze(n), ze(t), ze(o);
      }, e.isSupported = i;
    }(Wt || (Wt = {})), function (e) {
      function n(n) {
        return new Promise(function (t) {
          if (!r()) throw B;
          t(ln(e.SharingAPIMessages.shareWebContent, n));
        });
      }
      function t(e) {
        if (!(e && e.content && e.content.length)) throw {
          errorCode: M.INVALID_ARGUMENTS,
          message: "Shared content is missing"
        };
      }
      function o(e) {
        if (e.content.some(function (e) {
          return !e.type;
        })) throw {
          errorCode: M.INVALID_ARGUMENTS,
          message: "Shared content type cannot be undefined"
        };
        if (e.content.some(function (n) {
          return n.type !== e.content[0].type;
        })) throw {
          errorCode: M.INVALID_ARGUMENTS,
          message: "Shared content must be of the same type"
        };
      }
      function i(e) {
        if ("URL" !== e.content[0].type) throw {
          errorCode: M.INVALID_ARGUMENTS,
          message: "Content type is unsupported"
        };
        if (e.content.some(function (e) {
          return !e.url;
        })) throw {
          errorCode: M.INVALID_ARGUMENTS,
          message: "URLs are required for URL content types"
        };
      }
      function r() {
        return !(!Ae(le) || !le.supports.sharing);
      }
      e.SharingAPIMessages = {
        shareWebContent: "sharing.shareWebContent"
      }, e.shareWebContent = function (e, r) {
        try {
          t(e), o(e), i(e);
        } catch (e) {
          return X(function () {
            return Promise.reject(e);
          }, r);
        }
        return Ae(le, U.content, U.sidePanel, U.task, U.stage, U.meetingStage), X(n, r, e);
      }, e.isSupported = r;
    }(xt || (xt = {})), function (e) {
      function n() {
        return !(!Ae(le) || !le.supports.stageView);
      }
      e.open = function (e) {
        return new Promise(function (t) {
          if (Ae(le, U.content), !n()) throw B;
          if (!e) throw new Error("[stageView.open] Stage view params cannot be null");
          t(ln("stageView.open", e));
        });
      }, e.isSupported = n;
    }(_t || (_t = {})), function (e) {
      function n() {
        return !(!Ae(le) || !le.supports.webStorage);
      }
      e.isWebStorageClearedOnUserLogOut = function () {
        return Ae(le), n();
      }, e.isSupported = n;
    }(Bt || (Bt = {})), function (e) {
      var n;
      function t() {
        return !(!Ae(le) || !le.supports.call);
      }
      !function (e) {
        e.Unknown = "unknown", e.Audio = "audio", e.Video = "video", e.VideoBasedScreenSharing = "videoBasedScreenSharing", e.Data = "data";
      }(n = e.CallModalities || (e.CallModalities = {})), e.startCall = function (e) {
        return new Promise(function (o) {
          var i;
          if (Ae(le, U.content, U.task), !t()) throw B;
          if (!le.isLegacyTeams) return dn("call.startCall", [e], o);
          o(ln("executeDeepLink", it(e.targets, null === (i = e.requestedModalities) || void 0 === i ? void 0 : i.includes(n.Video), e.source)));
        });
      }, e.isSupported = t;
    }(zt || (zt = {})), function (e) {
      e.Messages = be.Messages, e.FailedReason = be.FailedReason, e.ExpectedFailureReason = be.ExpectedFailureReason, e.notifyAppLoaded = function () {
        be.notifyAppLoaded();
      }, e.notifySuccess = function () {
        be.notifySuccess();
      }, e.notifyFailure = function (e) {
        be.notifyFailure(e);
      }, e.notifyExpectedFailure = function (e) {
        be.notifyExpectedFailure(e);
      };
    }(jt || (jt = {})), function (e) {
      e.setValidityState = function (e) {
        Te.config.setValidityState(e);
      }, e.getSettings = function (e) {
        Ae(le, U.content, U.settings, U.remove, U.sidePanel), Te.getConfig().then(function (n) {
          e(n);
        });
      }, e.setSettings = function (e, n) {
        Ae(le, U.content, U.settings, U.sidePanel), n = n || q(), Te.config.setConfig(e).then(function () {
          n(!0);
        }).catch(function (e) {
          n(!1, e.message);
        });
      }, e.registerOnSaveHandler = function (e) {
        Te.config.registerOnSaveHandlerHelper(e);
      }, e.registerOnRemoveHandler = function (e) {
        Te.config.registerOnRemoveHandlerHelper(e);
      };
    }(Gt || (Gt = {}));
    var yo,
      So,
      bo,
      To = function (e, n) {
        var t = {};
        for (var o in e) Object.prototype.hasOwnProperty.call(e, o) && n.indexOf(o) < 0 && (t[o] = e[o]);
        if (null != e && "function" == typeof Object.getOwnPropertySymbols) {
          var i = 0;
          for (o = Object.getOwnPropertySymbols(e); i < o.length; i++) n.indexOf(o[i]) < 0 && Object.prototype.propertyIsEnumerable.call(e, o[i]) && (t[o[i]] = e[o[i]]);
        }
        return t;
      };
    !function (e) {
      function n(e) {
        return {
          url: e.url,
          size: {
            height: e.height ? e.height : _.Small,
            width: e.width ? e.width : _.Small
          },
          title: e.title,
          fallbackUrl: e.fallbackUrl
        };
      }
      function t(e) {
        return {
          url: e.url,
          size: {
            height: e.height ? e.height : _.Small,
            width: e.width ? e.width : _.Small
          },
          title: e.title,
          fallbackUrl: e.fallbackUrl,
          completionBotId: e.completionBotId
        };
      }
      function o(e) {
        return e.height = e.height ? e.height : _.Small, e.width = e.width ? e.width : _.Small, e;
      }
      e.startTask = function (e, o) {
        var i = o ? function (e) {
          return o(e.err, e.result);
        } : void 0;
        return void 0 === e.card && void 0 === e.url || e.card ? (Ae(le, U.content, U.sidePanel, U.meetingStage), dn("tasks.startTask", [e], o)) : void 0 !== e.completionBotId ? ve.url.bot.open(t(e), i) : ve.url.open(n(e), i), new Pt();
      }, e.updateTask = function (e) {
        (e = o(e)).width, e.height;
        var n = To(e, ["width", "height"]);
        if (Object.keys(n).length) throw new Error("resize requires a TaskInfo argument containing only width and height");
        ve.update.resize(e);
      }, e.submitTask = function (e, n) {
        ve.url.submit(e, n);
      }, e.getDefaultSizeIfNotProvided = o;
    }(yo || (yo = {})), function (e) {
      e.guest = "Guest", e.attendee = "Attendee", e.presenter = "Presenter", e.organizer = "Organizer";
    }(So || (So = {})), function (e) {
      e.added = "Added", e.alreadyExists = "AlreadyExists", e.conflict = "Conflict", e.notFound = "NotFound";
    }(bo || (bo = {}));
    var Io,
      Eo,
      Po,
      Fo,
      ko,
      Ao,
      Ro,
      Oo = function () {
        function e() {}
        return e.prototype.getFluidTenantInfo = function () {
          return new Promise(function (e) {
            Ae(le, U.meetingStage, U.sidePanel), e(ln("interactive.getFluidTenantInfo"));
          });
        }, e.prototype.getFluidToken = function (e) {
          return new Promise(function (n) {
            Ae(le, U.meetingStage, U.sidePanel), n(ln("interactive.getFluidToken", e));
          });
        }, e.prototype.getFluidContainerId = function () {
          return new Promise(function (e) {
            Ae(le, U.meetingStage, U.sidePanel), e(ln("interactive.getFluidContainerId"));
          });
        }, e.prototype.setFluidContainerId = function (e) {
          return new Promise(function (n) {
            Ae(le, U.meetingStage, U.sidePanel), n(ln("interactive.setFluidContainerId", e));
          });
        }, e.prototype.getNtpTime = function () {
          return new Promise(function (e) {
            Ae(le, U.meetingStage, U.sidePanel), e(ln("interactive.getNtpTime"));
          });
        }, e.prototype.registerClientId = function (e) {
          return new Promise(function (n) {
            Ae(le, U.meetingStage, U.sidePanel), n(ln("interactive.registerClientId", e));
          });
        }, e.prototype.getClientRoles = function (e) {
          return new Promise(function (n) {
            Ae(le, U.meetingStage, U.sidePanel), n(ln("interactive.getClientRoles", e));
          });
        }, e.create = function () {
          return Ae(le, U.meetingStage, U.sidePanel), new e();
        }, e;
      }();
    !function (e) {
      function n(e, n) {
        return {
          errorCode: e,
          message: n
        };
      }
      !function (e) {
        e.Dropbox = "DROPBOX", e.Box = "BOX", e.Sharefile = "SHAREFILE", e.GoogleDrive = "GOOGLEDRIVE", e.Egnyte = "EGNYTE", e.SharePoint = "SharePoint";
      }(e.CloudStorageProvider || (e.CloudStorageProvider = {})), function (e) {
        e[e.Sharepoint = 0] = "Sharepoint", e[e.WopiIntegration = 1] = "WopiIntegration", e[e.Google = 2] = "Google", e[e.OneDrive = 3] = "OneDrive", e[e.Recent = 4] = "Recent", e[e.Aggregate = 5] = "Aggregate", e[e.FileSystem = 6] = "FileSystem", e[e.Search = 7] = "Search", e[e.AllFiles = 8] = "AllFiles", e[e.SharedWithMe = 9] = "SharedWithMe";
      }(e.CloudStorageProviderType || (e.CloudStorageProviderType = {})), function (e) {
        e.ClassMaterials = "classMaterials";
      }(e.SpecialDocumentLibraryType || (e.SpecialDocumentLibraryType = {})), function (e) {
        e.Readonly = "readonly";
      }(e.DocumentLibraryAccessType || (e.DocumentLibraryAccessType = {})), function (e) {
        e.Downloaded = "Downloaded", e.Downloading = "Downloading", e.Failed = "Failed";
      }(e.FileDownloadStatus || (e.FileDownloadStatus = {})), function (e) {
        e.Download = "DOWNLOAD", e.Upload = "UPLOAD", e.Delete = "DELETE";
      }(e.CloudStorageProviderFileAction || (e.CloudStorageProviderFileAction = {})), e.getCloudStorageFolders = function (e, n) {
        if (Ae(le, U.content), !e || 0 === e.length) throw new Error("[files.getCloudStorageFolders] channelId name cannot be null or empty");
        if (!n) throw new Error("[files.getCloudStorageFolders] Callback cannot be null");
        dn("files.getCloudStorageFolders", [e], n);
      }, e.addCloudStorageFolder = function (e, n) {
        if (Ae(le, U.content), !e || 0 === e.length) throw new Error("[files.addCloudStorageFolder] channelId name cannot be null or empty");
        if (!n) throw new Error("[files.addCloudStorageFolder] Callback cannot be null");
        dn("files.addCloudStorageFolder", [e], n);
      }, e.deleteCloudStorageFolder = function (e, n, t) {
        if (Ae(le, U.content), !e) throw new Error("[files.deleteCloudStorageFolder] channelId name cannot be null or empty");
        if (!n) throw new Error("[files.deleteCloudStorageFolder] folderToDelete cannot be null or empty");
        if (!t) throw new Error("[files.deleteCloudStorageFolder] Callback cannot be null");
        dn("files.deleteCloudStorageFolder", [e, n], t);
      }, e.getCloudStorageFolderContents = function (e, n, t) {
        if (Ae(le, U.content), !e || !n) throw new Error("[files.getCloudStorageFolderContents] folder/providerCode name cannot be null or empty");
        if (!t) throw new Error("[files.getCloudStorageFolderContents] Callback cannot be null");
        if ("isSubdirectory" in e && !e.isSubdirectory) throw new Error("[files.getCloudStorageFolderContents] provided folder is not a subDirectory");
        dn("files.getCloudStorageFolderContents", [e, n], t);
      }, e.openCloudStorageFile = function (e, n, t) {
        if (Ae(le, U.content), !e || !n) throw new Error("[files.openCloudStorageFile] file/providerCode cannot be null or empty");
        if (e.isSubdirectory) throw new Error("[files.openCloudStorageFile] provided file is a subDirectory");
        dn("files.openCloudStorageFile", [e, n, t]);
      }, e.getExternalProviders = function (e, n) {
        if (void 0 === e && (e = !1), Ae(le, U.content), !n) throw new Error("[files.getExternalProviders] Callback cannot be null");
        dn("files.getExternalProviders", [e], n);
      }, e.copyMoveFiles = function (e, n, t, o, i, r) {
        if (void 0 === i && (i = !1), Ae(le, U.content), !e || 0 === e.length) throw new Error("[files.copyMoveFiles] selectedFiles cannot be null or empty");
        if (!n) throw new Error("[files.copyMoveFiles] providerCode cannot be null or empty");
        if (!t) throw new Error("[files.copyMoveFiles] destinationFolder cannot be null or empty");
        if (!o) throw new Error("[files.copyMoveFiles] destinationProviderCode cannot be null or empty");
        if (!r) throw new Error("[files.copyMoveFiles] callback cannot be null");
        dn("files.copyMoveFiles", [e, n, t, o, i], r);
      }, e.getFileDownloads = function (e) {
        if (Ae(le, U.content), !e) throw new Error("[files.getFileDownloads] Callback cannot be null");
        dn("files.getFileDownloads", [], e);
      }, e.openDownloadFolder = function (e, n) {
        if (void 0 === e && (e = void 0), Ae(le, U.content), !n) throw new Error("[files.openDownloadFolder] Callback cannot be null");
        dn("files.openDownloadFolder", [e], n);
      }, e.addCloudStorageProvider = function (e) {
        if (Ae(le, U.content), !e) throw n(M.INVALID_ARGUMENTS, "[files.addCloudStorageProvider] callback cannot be null");
        dn("files.addCloudStorageProvider", [], e);
      }, e.removeCloudStorageProvider = function (e, t) {
        if (Ae(le, U.content), !t) throw n(M.INVALID_ARGUMENTS, "[files.removeCloudStorageProvider] callback cannot be null");
        if (!e || !e.content) throw n(M.INVALID_ARGUMENTS, "[files.removeCloudStorageProvider] 3P cloud storage provider request content is missing");
        dn("files.removeCloudStorageProvider", [e], t);
      }, e.addCloudStorageProviderFile = function (e, t) {
        if (Ae(le, U.content), !t) throw n(M.INVALID_ARGUMENTS, "[files.addCloudStorageProviderFile] callback cannot be null");
        if (!e || !e.content) throw n(M.INVALID_ARGUMENTS, "[files.addCloudStorageProviderFile] 3P cloud storage provider request content is missing");
        dn("files.addCloudStorageProviderFile", [e], t);
      }, e.renameCloudStorageProviderFile = function (e, t) {
        if (Ae(le, U.content), !t) throw n(M.INVALID_ARGUMENTS, "[files.renameCloudStorageProviderFile] callback cannot be null");
        if (!e || !e.content) throw n(M.INVALID_ARGUMENTS, "[files.renameCloudStorageProviderFile] 3P cloud storage provider request content is missing");
        dn("files.renameCloudStorageProviderFile", [e], t);
      }, e.deleteCloudStorageProviderFile = function (e, t) {
        if (Ae(le, U.content), !t) throw n(M.INVALID_ARGUMENTS, "[files.deleteCloudStorageProviderFile] callback cannot be null");
        if (!(e && e.content && e.content.itemList && e.content.itemList.length > 0)) throw n(M.INVALID_ARGUMENTS, "[files.deleteCloudStorageProviderFile] 3P cloud storage provider request content details are missing");
        dn("files.deleteCloudStorageProviderFile", [e], t);
      }, e.downloadCloudStorageProviderFile = function (e, t) {
        if (Ae(le, U.content), !t) throw n(M.INVALID_ARGUMENTS, "[files.downloadCloudStorageProviderFile] callback cannot be null");
        if (!(e && e.content && e.content.itemList && e.content.itemList.length > 0)) throw n(M.INVALID_ARGUMENTS, "[files.downloadCloudStorageProviderFile] 3P cloud storage provider request content details are missing");
        dn("files.downloadCloudStorageProviderFile", [e], t);
      }, e.uploadCloudStorageProviderFile = function (e, t) {
        if (Ae(le, U.content), !t) throw n(M.INVALID_ARGUMENTS, "[files.uploadCloudStorageProviderFile] callback cannot be null");
        if (!(e && e.content && e.content.itemList && e.content.itemList.length > 0)) throw n(M.INVALID_ARGUMENTS, "[files.uploadCloudStorageProviderFile] 3P cloud storage provider request content details are missing");
        if (!e.content.destinationFolder) throw n(M.INVALID_ARGUMENTS, "[files.uploadCloudStorageProviderFile] Invalid destination folder details");
        dn("files.uploadCloudStorageProviderFile", [e], t);
      }, e.registerCloudStorageProviderListChangeHandler = function (e) {
        if (Ae(le), !e) throw new Error("[registerCloudStorageProviderListChangeHandler] Handler cannot be null");
        Be("files.cloudStorageProviderListChange", e);
      }, e.registerCloudStorageProviderContentChangeHandler = function (e) {
        if (Ae(le), !e) throw new Error("[registerCloudStorageProviderContentChangeHandler] Handler cannot be null");
        Be("files.cloudStorageProviderContentChange", e);
      };
    }(Io || (Io = {})), function (e) {
      function n() {
        return !(!Ae(le) || !le.supports.meetingRoom);
      }
      e.getPairedMeetingRoomInfo = function () {
        return new Promise(function (e) {
          if (Ae(le), !n()) throw B;
          e(ln("meetingRoom.getPairedMeetingRoomInfo"));
        });
      }, e.sendCommandToPairedMeetingRoom = function (e) {
        return new Promise(function (t) {
          if (!e || 0 == e.length) throw new Error("[meetingRoom.sendCommandToPairedMeetingRoom] Command name cannot be null or empty");
          if (Ae(le), !n()) throw B;
          t(ln("meetingRoom.sendCommandToPairedMeetingRoom", e));
        });
      }, e.registerMeetingRoomCapabilitiesUpdateHandler = function (e) {
        if (!e) throw new Error("[meetingRoom.registerMeetingRoomCapabilitiesUpdateHandler] Handler cannot be null");
        if (Ae(le), !n()) throw B;
        Be("meetingRoom.meetingRoomCapabilitiesUpdate", function (n) {
          Ae(le), e(n);
        });
      }, e.registerMeetingRoomStatesUpdateHandler = function (e) {
        if (!e) throw new Error("[meetingRoom.registerMeetingRoomStatesUpdateHandler] Handler cannot be null");
        if (Ae(le), !n()) throw B;
        Be("meetingRoom.meetingRoomStatesUpdate", function (n) {
          Ae(le), e(n);
        });
      }, e.isSupported = n;
    }(Eo || (Eo = {})), function (e) {
      function n() {
        return !(!Ae(le) || !le.supports.notifications);
      }
      e.showNotification = function (e) {
        if (Ae(le, U.content), !n()) throw B;
        dn("notifications.showNotification", [e]);
      }, e.isSupported = n;
    }(Po || (Po = {})), function (e) {
      function n() {
        return !(!Ae(le) || !le.supports.remoteCamera);
      }
      !function (e) {
        e.Reset = "Reset", e.ZoomIn = "ZoomIn", e.ZoomOut = "ZoomOut", e.PanLeft = "PanLeft", e.PanRight = "PanRight", e.TiltUp = "TiltUp", e.TiltDown = "TiltDown";
      }(e.ControlCommand || (e.ControlCommand = {})), function (e) {
        e[e.CommandResetError = 0] = "CommandResetError", e[e.CommandZoomInError = 1] = "CommandZoomInError", e[e.CommandZoomOutError = 2] = "CommandZoomOutError", e[e.CommandPanLeftError = 3] = "CommandPanLeftError", e[e.CommandPanRightError = 4] = "CommandPanRightError", e[e.CommandTiltUpError = 5] = "CommandTiltUpError", e[e.CommandTiltDownError = 6] = "CommandTiltDownError", e[e.SendDataError = 7] = "SendDataError";
      }(e.ErrorReason || (e.ErrorReason = {})), function (e) {
        e[e.None = 0] = "None", e[e.ControlDenied = 1] = "ControlDenied", e[e.ControlNoResponse = 2] = "ControlNoResponse", e[e.ControlBusy = 3] = "ControlBusy", e[e.AckTimeout = 4] = "AckTimeout", e[e.ControlTerminated = 5] = "ControlTerminated", e[e.ControllerTerminated = 6] = "ControllerTerminated", e[e.DataChannelError = 7] = "DataChannelError", e[e.ControllerCancelled = 8] = "ControllerCancelled", e[e.ControlDisabled = 9] = "ControlDisabled", e[e.ControlTerminatedToAllowOtherController = 10] = "ControlTerminatedToAllowOtherController";
      }(e.SessionTerminatedReason || (e.SessionTerminatedReason = {})), e.getCapableParticipants = function (e) {
        if (!e) throw new Error("[remoteCamera.getCapableParticipants] Callback cannot be null");
        if (Ae(le, U.sidePanel), !n()) throw B;
        dn("remoteCamera.getCapableParticipants", e);
      }, e.requestControl = function (e, t) {
        if (!e) throw new Error("[remoteCamera.requestControl] Participant cannot be null");
        if (!t) throw new Error("[remoteCamera.requestControl] Callback cannot be null");
        if (Ae(le, U.sidePanel), !n()) throw B;
        dn("remoteCamera.requestControl", [e], t);
      }, e.sendControlCommand = function (e, t) {
        if (!e) throw new Error("[remoteCamera.sendControlCommand] ControlCommand cannot be null");
        if (!t) throw new Error("[remoteCamera.sendControlCommand] Callback cannot be null");
        if (Ae(le, U.sidePanel), !n()) throw B;
        dn("remoteCamera.sendControlCommand", [e], t);
      }, e.terminateSession = function (e) {
        if (!e) throw new Error("[remoteCamera.terminateSession] Callback cannot be null");
        if (Ae(le, U.sidePanel), !n()) throw B;
        dn("remoteCamera.terminateSession", e);
      }, e.registerOnCapableParticipantsChangeHandler = function (e) {
        if (!e) throw new Error("[remoteCamera.registerOnCapableParticipantsChangeHandler] Handler cannot be null");
        if (Ae(le, U.sidePanel), !n()) throw B;
        Be("remoteCamera.capableParticipantsChange", e);
      }, e.registerOnErrorHandler = function (e) {
        if (!e) throw new Error("[remoteCamera.registerOnErrorHandler] Handler cannot be null");
        if (Ae(le, U.sidePanel), !n()) throw B;
        Be("remoteCamera.handlerError", e);
      }, e.registerOnDeviceStateChangeHandler = function (e) {
        if (!e) throw new Error("[remoteCamera.registerOnDeviceStateChangeHandler] Handler cannot be null");
        if (Ae(le, U.sidePanel), !n()) throw B;
        Be("remoteCamera.deviceStateChange", e);
      }, e.registerOnSessionStatusChangeHandler = function (e) {
        if (!e) throw new Error("[remoteCamera.registerOnSessionStatusChangeHandler] Handler cannot be null");
        if (Ae(le, U.sidePanel), !n()) throw B;
        Be("remoteCamera.sessionStatusChange", e);
      }, e.isSupported = n;
    }(Fo || (Fo = {})), function (e) {
      function n() {
        return !(!Ae(le) || !le.supports.appEntity);
      }
      e.selectAppEntity = function (e, t, o, i) {
        if (Ae(le, U.content), !n()) throw B;
        if (!e || 0 == e.length) throw new Error("[appEntity.selectAppEntity] threadId name cannot be null or empty");
        if (!i) throw new Error("[appEntity.selectAppEntity] Callback cannot be null");
        dn("appEntity.selectAppEntity", [e, t, o], i);
      }, e.isSupported = n;
    }(ko || (ko = {})), function (e) {
      function n() {
        return !(!Ae(le) || !le.supports.teams);
      }
      !function (e) {
        e[e.Regular = 0] = "Regular", e[e.Private = 1] = "Private", e[e.Shared = 2] = "Shared";
      }(e.ChannelType || (e.ChannelType = {})), e.getTeamChannels = function (e, t) {
        if (Ae(le, U.content), !n()) throw B;
        if (!e) throw new Error("[teams.getTeamChannels] groupId cannot be null or empty");
        if (!t) throw new Error("[teams.getTeamChannels] Callback cannot be null");
        dn("teams.getTeamChannels", [e], t);
      }, e.refreshSiteUrl = function (e, t) {
        if (Ae(le), !n()) throw B;
        if (!e) throw new Error("[teams.refreshSiteUrl] threadId cannot be null or empty");
        if (!t) throw new Error("[teams.refreshSiteUrl] Callback cannot be null");
        dn("teams.refreshSiteUrl", [e], t);
      }, e.isSupported = n, function (e) {
        function n() {
          return !(!Ae(le) || !le.supports.teams) && !!le.supports.teams.fullTrust;
        }
        !function (e) {
          function n() {
            return !(!Ae(le) || !le.supports.teams) && !!le.supports.teams.fullTrust && !!le.supports.teams.fullTrust.joinedTeams;
          }
          e.getUserJoinedTeams = function (e) {
            return new Promise(function (t) {
              if (Ae(le), !n()) throw B;
              if ((v.hostClientType === N.android || v.hostClientType === N.teamsRoomsAndroid || v.hostClientType === N.teamsPhones || v.hostClientType === N.teamsDisplays) && !Re(a)) {
                var o = {
                  errorCode: M.OLD_PLATFORM
                };
                throw new Error(JSON.stringify(o));
              }
              t(rn("getUserJoinedTeams", e));
            });
          }, e.isSupported = n;
        }(e.joinedTeams || (e.joinedTeams = {})), e.getConfigSetting = function (e) {
          return new Promise(function (t) {
            if (Ae(le), !n()) throw B;
            t(rn("getConfigSetting", e));
          });
        }, e.isSupported = n;
      }(e.fullTrust || (e.fullTrust = {}));
    }(Ao || (Ao = {})), function (e) {
      var n;
      function t() {
        return Ae(le), Vt.isSupported();
      }
      function o(e, t) {
        void 0 === t && (t = n.Warn), dn("video.notifyError", [e, t]);
      }
      !function (e) {
        e.Fatal = "fatal", e.Warn = "warn";
      }(n = e.ErrorLevel || (e.ErrorLevel = {})), e.notifySelectedVideoEffectChanged = function (e, n, o) {
        if (Ae(le, U.sidePanel), !t()) throw B;
        dn("video.videoEffectChanged", [e, n, o]);
      }, e.registerForVideoEffect = function (e) {
        if (Ae(le, U.sidePanel), !t()) throw B;
        Be("video.effectParameterChange", e, !1), dn("video.registerForVideoEffect");
      }, e.updatePersonalizedEffects = function (e) {
        if (Ae(le, U.sidePanel), !Vt.isSupported()) throw B;
        dn("video.personalizedEffectsChanged", [e]);
      }, e.isSupported = t, e.notifyFatalError = function (e) {
        if (Ae(le), !Vt.isSupported()) throw B;
        o(e, n.Fatal);
      }, function (e) {
        function n() {
          return Ae(le), Vt.sharedFrame.isSupported();
        }
        function t(e) {
          dn("video.videoFrameProcessed", [e]);
        }
        e.isSupported = n, e.registerForVideoFrame = function (e, i) {
          if (Ae(le, U.sidePanel), !n()) throw B;
          Be("video.newVideoFrame", function (n) {
            if (n) {
              var i = n.timestamp;
              e(n, function () {
                t(i);
              }, o);
            }
          }, !1), dn("video.registerForVideoFrame", [i]);
        };
      }(e.sharedFrame || (e.sharedFrame = {}));
    }(Ro || (Ro = {}));
  })(), o;
})());
